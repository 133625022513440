import { Component, OnInit, Inject } from '@angular/core';
import { ErrorLog } from '../error-log';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-errorlog-dialog',
  templateUrl: './errorlog-dialog.component.html',
  styleUrls: ['../error-log.component.css']
})
export class ErrorlogDialogComponent implements OnInit {

  errorLog : ErrorLog

  constructor( @Inject(MAT_DIALOG_DATA) private data : any) { }

  ngOnInit() {
    this.errorLog = this.data
  }

}
