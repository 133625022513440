import { ArticleCategoryComponent } from './article/article-category/article-category.component';
import { ArticleEditComponent } from './article/articles/article-edit.component';
import { ArticleComponent } from './article/articles/article.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AccountComponent } from './account/account.component';
import { AuthGuard } from './authorization/auth.guard';
import { AccountEditComponent } from './account/account-edit.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ErrorLogComponent } from './error-log/error-log.component';
import { ActionLogComponent } from './action-log/action-log.component';
import { ProductsComponent } from './product/products/products.component';
import { ProductCategoriesComponent } from './product/product-categories/product-categories.component';
import { AttributesComponent } from './product/attributes/attributes.component';
import { ProductEditComponent } from './product/products/product-edit.component';
import { AttributeEditComponent } from './product/attributes/attribute-edit.component';
import { CategoryEditComponent } from './product/product-categories/category-edit.component';
import { CarouselComponent } from './carousel/carousel.component';
import { CarouselEditComponent } from './carousel/carousel-edit.component';
import { StoryComponent } from './story/story.component';
import { StoryEditComponent } from './story/story-edit.component';
import { KeywordComponent } from './keyword/keyword.component';

const routes: Routes = [
  {
    path: "", redirectTo: "dashboard", pathMatch: "full"
  },
  {
    path: "login", component: LoginComponent
  },
  {
    path: "dashboard", component: DashboardComponent, canActivate: [AuthGuard],
    children: [
      {
        path: "account", component: AccountComponent
      },
      {
        path: "account/:userId", component: AccountEditComponent
      },
      {
        path: "changePassword", component: ChangePasswordComponent
      },
      {
        path: "errorLog", component: ErrorLogComponent
      },
      {
        path: "actionLog", component: ActionLogComponent
      },
      {
        path: "products", component: ProductsComponent
      },
      {
        path: "products/:id/:from", component: ProductEditComponent
      },
      {
        path: "categories", component: ProductCategoriesComponent
      },
      {
        path: "categories/:id/:from", component: CategoryEditComponent
      },
      {
        path: "attributes", component: AttributesComponent
      },
      {
        path: "attributes/:id", component: AttributeEditComponent
      },
      {
        path: "carousel", component: CarouselComponent
      },
      {
        path: "carousel/:id", component: CarouselEditComponent
      },
      {
        path: "story", component:StoryComponent
      },
      {
        path: "story/:id", component:StoryEditComponent
      },
      {
        path: "keywordSetting", component:KeywordComponent
      },
      {
        path: "article", component:ArticleComponent
      },
      {
        path: "article/:id", component:ArticleEditComponent
      },
      {
        path: "article-category", component: ArticleCategoryComponent
      }
    ]
  },
  {
    path: "**", redirectTo: "/"
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
