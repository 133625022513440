import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Story } from './story';
import { StoryService } from './story.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-story-edit',
  templateUrl: './story-edit.component.html',
  styleUrls: ['./story.component.css']
})
export class StoryEditComponent implements OnInit {

  id: string
  name: string
  content: string
  isNew = false
  story = new Story();
  selectedLanguage: string
  target = environment.serverUrl + "/public/file/"

  iconImage: File = null
  iconImageSrc : SafeResourceUrl= ""

  constructor(private storySrv: StoryService, private router: Router,
    private route: ActivatedRoute, private toastrSrv: ToastrService,
    private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.selectedLanguage = "zh_TW"
    this.route.params.subscribe(param => this.id = param.id)
    if (this.id === "null") {
      this.isNew = true;
      this.story.status = "ACTIVE"
      this.story.title = new Map();
      this.story.content = new Map();
    } else {
      this.storySrv.getStoryById(this.id).subscribe(rep => {
        this.story = rep.body
        this.name = this.story.title[this.selectedLanguage]
        this.content = this.story.content[this.selectedLanguage]
        this.iconImageSrc = this.target + this.story.iconIds[this.selectedLanguage]
      })
    }
  }

  save() {
    console.log("???")
    if (this.isNew) {
      if (this.checkNull()) {
        this.storySrv.create(this.story, this.selectedLanguage).subscribe(rep => {
          if (rep.status == 200) {
            this.story = rep.body
            const fd = new FormData()
            fd.set("file", this.iconImage)
            this.storySrv.uploadIconImg(this.story, fd, this.selectedLanguage).subscribe(() => {
              this.iconImage = null;
              this.isNew = false
              this.toastrSrv.success("新增 " + this.story.title[this.selectedLanguage] + " 成功", "Success")
            })
          }
        })
      }
    } else {
      if (this.checkNull()) {
        this.storySrv.update(this.story).subscribe(rep => {
          if (rep.status == 200) {
            if (this.iconImage) {
              const fd = new FormData()
              fd.set("file", this.iconImage)
              this.storySrv.uploadIconImg(this.story, fd, this.selectedLanguage).subscribe(() => {
                this.iconImage = null;
              })
            }
            this.toastrSrv.success("修改 " + this.story.title[this.selectedLanguage] + " 成功", "success")
          }
        })
      }
    }
  }

  checkNull(): boolean {
    if (this.isNew) {
      if (this.iconImage && this.name && this.content) {
        this.story.title.set(this.selectedLanguage, this.name)
        this.story.content.set(this.selectedLanguage, this.content)
        return true
      }
    } else {
      if (this.name && this.content) {
        this.story.title[this.selectedLanguage] = this.name;
        this.story.content[this.selectedLanguage] = this.content;
        return true
      }
    }
    this.toastrSrv.info("必填欄位不可為空!", "提醒")
    return false
  }

  cancel() {
    history.go(-1)
    this.router.navigate(['/dashboard', 'story'])
  }

  fileUpload(event) {
    this.iconImage = <File>event.target.files[0]
    if (this.iconImage) {
      let reader = new FileReader();
      reader.onload = (e: any) => {
        this.iconImageSrc = this.sanitizer.bypassSecurityTrustResourceUrl(e.target.result)
      }
      reader.readAsDataURL(this.iconImage)
    }
  }

  changeLanguage(){}
}
