import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Story } from './story';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StoryService {

  constructor(private http : HttpClient) { }

  public getStoriesByStatus(status: string, page, size){
    return this.http.get<Story[]>(environment.serverUrl + "/public/successStory/status/" + status + "?page=" + page + "&size=" + size + "&sort=priority,asc", { observe: "response" })
  }

  public getStoryById(id: string) {
    return this.http.get<Story>(environment.serverUrl + "/public/successStory/" + id, { observe: "response" })
  }

  public search(status: string, keyword: string, page: number, size: number) {
    return this.http.get<Story[]>(environment.serverUrl + "/public/successStory/status/" + status + "/language/zh_TW/titleValue/" + keyword + "?page=" + page + "&size=" + size, { observe: "response" })
  }

  public create(story:Story, language: string) {
    switch (language) {
      case "zh_TW":
        return this.http.post<Story>(environment.serverUrl + "/super/successStory",
          {
            "title": { "zh_TW": story.title.get(language) },
            "content": { "zh_TW": story.content.get(language)},
            "status": story.status,      
          },
          { observe: "response" })
      case "en_US":
        return this.http.post<Story>(environment.serverUrl + "/super/successStory",
          {
            "title": { "en_US": story.title.get(language) },
            "content": { "en_US": story.content.get(language)},
            "status": story.status,
          },
          { observe: "response" })
      default:
        throw Error("語系錯誤，請確認語系為中文或英文")
    }
  }

  public update(story:Story) {
    return this.http.put(environment.serverUrl + "/super/successStory", story, { observe: "response" })
  }

  public delete(ids: string[]) {
    return this.http.request("delete", environment.serverUrl + "/super/successStory", { body: ids, observe: "response" })
  }

  public changeSort(stories: Story[]) {
    return this.http.put(environment.serverUrl + "/super/successStory/priority", stories, { observe: "response" })
  }
  
  public uploadIconImg(story:Story, img: FormData, language: string) {
    return this.http.post(environment.serverUrl + "/super/successStory/" + story.id + "/language/" + language + "/upload/icon", img, {observe:"response"})
  }


}
