import { Component, OnInit, ViewChild } from '@angular/core';
import { AccountService } from './account.service';
import { Account } from './account';
import { MatTableDataSource, MatPaginator, MatDialog, MatPaginatorIntl } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DeleteDialogComponent } from '../global/delete-dialog/delete-dialog.component';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit {

  tabIndex: number
  keyword: string
  status: string
  account: Account
  accountDataSource = new MatTableDataSource<Account>();
  displayedColumns = ['userId', 'fullName', "action"]
  totalCount: number
  size: number = 10;
  page: number = 0;

  @ViewChild('paginator') paginator: MatPaginator

  constructor(private accSrv: AccountService, private router: Router,
    private toastrSrv: ToastrService, private deleteDialog: MatDialog,
    private matPaginatorIntl: MatPaginatorIntl) { }

  ngOnInit() {
    this.matPaginatorIntl.itemsPerPageLabel = '每頁筆數：';
    this.matPaginatorIntl.nextPageLabel = '下一頁';
    this.matPaginatorIntl.previousPageLabel = '上一頁';
    this.matPaginatorIntl.firstPageLabel = '第一頁'
    this.matPaginatorIntl.lastPageLabel = '最後一頁'
    this.tabIndex = 0
    this.refresh()
  }

  refresh() {
    this.status = this.tabIndex == 0 ? "ACTIVE" : "TERMINATED"
    if (this.keyword) {
      this.accSrv.search(this.status, this.keyword, this.page, this.size).subscribe(response => this.setData(response))
    } else {
      this.accSrv.getAllAccountByStatus(this.status, this.page, this.size).subscribe(response => this.setData(response))
    }
  }

  setData(rep){
    this.accountDataSource.data = rep.body['content'];
    this.totalCount = rep.body['totalElements']
  }

  newAccount() {
    this.router.navigate(['/dashboard', 'account', 'null']);
  }

  delete(account: Account) {
    let tempIds = []
    tempIds.push(account.userId)
    const dialogRef = this.deleteDialog.open(DeleteDialogComponent)
    dialogRef.afterClosed().subscribe(rep=>{
      if(rep == "Yes"){
        this.accSrv.delete(tempIds).subscribe(rep => {
          if (rep.status == 200) {
            this.toastrSrv.success('success', '刪除' + account.fullName + '成功')
            this.afterDelete()
          }
        })
      }
    })
  }

  afterDelete(){
    if ((this.totalCount % 10) === 1 && this.totalCount!=1) {
      this.page -= 1
      this.paginator.previousPage()
    } else {
      this.refresh()
    }
  }

  changeTab(){
    this.keyword = ""
    this.page = 0
    this.size = 10
    this.refresh()
  }

  search() {
    this.paginator.firstPage();
    this.refresh();
  }

  clear() {
    this.paginator.firstPage();
    this.keyword = ""
    this.refresh()
  }

  pageChanged(event) {
    this.page = event.pageIndex
    this.size = event.pageSize
    this.refresh()
  }

}
