import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ErrorLogQueryInfo } from './error-log-queryinfo';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ErrorLogService {

  constructor(private http: HttpClient) { }

  public get7DaysErrorLog(query: ErrorLogQueryInfo, page: number, size: number) {
    return this.http.post(environment.serverUrl + "/super/errorlog/interval?page=" + page + "&size=" + size + "&sort=millis,desc", query, { observe: "response" })
  }

  public searchErrorLog(query: ErrorLogQueryInfo, page: number, size: number) {
    return this.http.post(environment.serverUrl + "/super/errorlog/interval/message?page=" + page + "&size=" + size + "&sort=millis,desc", query, { observe: "response" })
  }
}
