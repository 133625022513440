import { Injectable, ErrorHandler, Injector } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { HttpErrorResponse } from "@angular/common/http";



@Injectable()
export class GlobalErrorHandler extends ErrorHandler{

    constructor(private injector : Injector){ super(); }

    private get toastrService() : ToastrService{
        return this.injector.get(ToastrService);
    }

    handleError(error : any) {
        if(error instanceof HttpErrorResponse){
            if(error.status == 0){
                this.toastrService.error("網路連線錯誤，請確認網路連結與伺服器運作正常！",
                "Error", { closeButton: true, timeOut: 5000, onActivateTick: true });
            }else{
                this.toastrService.error(error.error.message,
                    "Error", { closeButton: true, timeOut: 5000, onActivateTick: true });
            }
        }else{
            super.handleError(error)
        }
    }

}