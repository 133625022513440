import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule,
         MatInputModule,
         MatIconModule,
         MatButtonToggleModule,
         MatCardModule,
         MatButtonModule,
         MatExpansionModule,
         MatListModule,
         MatTabsModule,
         MatTableModule,
         MatGridListModule,
         MatRadioModule,
         MatSelectModule,
         MatPaginatorModule,
         MatDialogModule,
         MatChipsModule,
         MatTreeModule,
         MatCheckboxModule,
         MAT_DATE_LOCALE,
         MAT_DATE_FORMATS,
          } from '@angular/material';

export const TW_FORMATS = {
  parse: {
    dateInput: 'YYYY/MM/DD'
  },
  display: {
    dateInput: 'YYYY/MM/DD',
    monthYearLabel: 'YYYY MMM',
    dateA11yLabel: 'YYYY/MM/DD',
    monthYearA11yLabel: 'YYYY MMM'
  }
};


@NgModule({
  imports: [CommonModule],
  exports: [
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatExpansionModule,
    MatListModule,
    MatTabsModule,
    MatTableModule,
    MatGridListModule,
    MatRadioModule,
    MatSelectModule,
    MatPaginatorModule,
    MatDialogModule,
    MatChipsModule,
    MatTreeModule,
    MatCheckboxModule
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'zh-TW' },
    { provide: MAT_DATE_FORMATS, useValue: TW_FORMATS }
  ]
})
export class AppMaterialModule { }
