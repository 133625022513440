import { Component, OnInit, ViewChild } from '@angular/core';
import { AttributeService } from './attribute.service';
import { Attribute } from './attribute';
import { MatTableDataSource, MatPaginator, MatPaginatorIntl, MatDialog, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DeleteDialogComponent } from '../../global/delete-dialog/delete-dialog.component';

@Component({
  selector: 'app-attributes',
  templateUrl: './attributes.component.html',
  styleUrls: ['../products/products.component.css']
})
export class AttributesComponent implements OnInit {

  status: string
  totalCount: number
  attributeDBSource = new MatTableDataSource<Attribute>();
  displayedColumns = ['name', 'action']
  keyword: string
  tabIndex: number
  page = 0
  size = 10

  @ViewChild('paginator') paginator: MatPaginator

  constructor(private attributeSrv: AttributeService, private router: Router,
    private toastrSrv: ToastrService, private matPaginatorIntl: MatPaginatorIntl,
    private deleteDialog : MatDialog, ) { }

  ngOnInit() {
    this.matPaginatorIntl.itemsPerPageLabel = '每頁筆數：';
    this.matPaginatorIntl.nextPageLabel = '下一頁';
    this.matPaginatorIntl.previousPageLabel = '上一頁';
    this.matPaginatorIntl.firstPageLabel = '第一頁'
    this.matPaginatorIntl.lastPageLabel = '最後一頁'
    this.tabIndex = 0
    this.refresh()
  }

  refresh() {
    if (this.tabIndex == 0) {
      this.status = "ACTIVE"
      if (this.keyword) {
        this.attributeSrv.search(this.status, this.keyword).subscribe(rep => {
          this.attributeDBSource.data = rep.body['content']
          this.totalCount = rep.body['totalElements']
        })
      } else {
        this.attributeSrv.getAttributesByStatus(this.status, this.page, this.size).subscribe(rep => {
          this.attributeDBSource.data = rep.body['content']
          this.totalCount = rep.body['totalElements']
        })
      }
    } else if (this.tabIndex == 1) {
      this.status = "INACTIVE"
      if (this.keyword) {
        this.attributeSrv.search(this.status, this.keyword).subscribe(rep => {
          this.attributeDBSource.data = rep.body['content']
          this.totalCount = rep.body['totalElements']
        })
      } else {
        this.attributeSrv.getAttributesByStatus(this.status, this.page, this.size).subscribe(rep => {
          this.attributeDBSource.data = rep.body['content']
          this.totalCount = rep.body['totalElements']
        })
      }
    }
  }

  changeTab(){
    this.keyword=""
    this.page = 0
    this.size = 10
    this.refresh()
  }

  newAttribute() {
    this.router.navigate(['/dashboard', 'attributes', 'null'])
  }

  delete(attribute: Attribute) {
    let tempIds = []
    tempIds.push(attribute.id)
    const dialogRef = this.deleteDialog.open(DeleteDialogComponent)
    dialogRef.afterClosed().subscribe(rep=>{
      if(rep == "Yes"){
        this.attributeSrv.delete(tempIds).subscribe(rep => {
          if (rep.status == 200) {
            this.toastrSrv.success('刪除' + attribute.title['zh_TW'] + '成功','Success')
            this.afterDelete()
          }
        })
      }
    })
  }

  afterDelete(){
    if((this.totalCount%10) ==1 && this.totalCount!=1){
      this.page-=1
      this.paginator.previousPage()
    }else{
      this.refresh()
    }
  }

  pageChanged(event) {
    this.page = event.pageIndex
    this.size = event.pageSize
    this.refresh()
  }

  search() {
    this.paginator.firstPage();
    this.refresh();
  }

  clear() {
    this.paginator.firstPage();
    this.keyword = ""
    this.refresh()
  }
}
