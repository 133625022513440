import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppMaterialModule } from '../app-material/app-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DndModule } from 'ngx-drag-drop';
import { ProductCategoriesComponent } from './product-categories/product-categories.component';
import { ProductsComponent } from './products/products.component';
import { AttributesComponent } from './attributes/attributes.component';
import { ProductEditComponent } from './products/product-edit.component';
import { AttributeEditComponent } from './attributes/attribute-edit.component';
import { TypeDialogComponent } from './type-dialog/type-dialog.component';
import { CategoryEditComponent } from './product-categories/category-edit.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search'



@NgModule({
  imports: [
    CommonModule,
    AppMaterialModule,
    DndModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgxMatSelectSearchModule
  ],
  entryComponents:[TypeDialogComponent],
  declarations: [ProductCategoriesComponent, ProductsComponent, AttributesComponent, ProductEditComponent, AttributeEditComponent, TypeDialogComponent, CategoryEditComponent]
})
export class ProductModule { }
