import { Injectable } from '@angular/core';
import { Authorization } from '../authorization/authorization';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  private _authInfo : Authorization
  constructor() { }

  public get authInfo(){
    return this._authInfo;
  }

  public set authInfo(authInfo:Authorization){
    this._authInfo = authInfo;
  }

}
