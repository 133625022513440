import { Component, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { MatTableDataSource, MatDialog, MatPaginatorIntl, MatPaginator } from '@angular/material';
import { ActionLog } from './action-log';
import { ActionLogService } from './action-log.service';
import { ActionLogQueryInfo } from './action-log-queryinfo';
import { ActionlogDialogComponent } from './actionlog-dialog/actionlog-dialog.component';

@Component({
  selector: 'app-action-log',
  templateUrl: './action-log.component.html',
  styleUrls: ['./action-log.component.css']
})
export class ActionLogComponent implements OnInit {


  selected: any;
  keyword: string = "";
  totalCount: number
  actionLogDBSource = new MatTableDataSource<ActionLog>();
  displayedColumns = ['operator', 'method', 'url', 'time', 'more'];
  ranges: any = {
    '今天': [moment(), moment()],
    '昨天': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    '近7日': [moment().subtract(6, 'days'), moment()],
    '近30日': [moment().subtract(29, 'days'), moment()],
    '本月': [moment().startOf('month'), moment().endOf('month')],
    '上月': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  }
  actionLog: ActionLog = new ActionLog();
  query: ActionLogQueryInfo = new ActionLogQueryInfo();
  page = 0
  size = 10

  @ViewChild('paginator') paginator : MatPaginator

  constructor(private actLogSrv: ActionLogService, private actionDialog: MatDialog,
    private matPaginatorIntl: MatPaginatorIntl) { }

  ngOnInit() {
    this.matPaginatorIntl.itemsPerPageLabel = '每頁筆數：';
    this.matPaginatorIntl.nextPageLabel = '下一頁';
    this.matPaginatorIntl.previousPageLabel = '上一頁';
    this.matPaginatorIntl.firstPageLabel = '第一頁'
    this.matPaginatorIntl.lastPageLabel = '最後一頁'
    this.selected = { startDate: moment().subtract(6, 'days'), endDate: moment() }
    this.refresh();
  }

  refresh(): void {
    if (this.keyword || this.selected) {
      if (this.selected) {
        this.query.startTime = this.selected.startDate._d.getTime();
        this.query.endTime = this.selected.endDate._d.getTime();
      }
      this.query.searchText = this.keyword
      this.actLogSrv.searchActionLog(this.query, this.page, this.size).subscribe(rep => {
        this.actionLogDBSource.data = rep.body['content']
        this.actionLogDBSource.data.forEach(actionLog => {
          actionLog.time = new Date(actionLog.timeStamp)
        })
        this.totalCount = rep.body['totalElements']
      })

    } else {
      this.query.startTime = moment().subtract(7, 'days').toDate().getTime()
      this.query.endTime = moment().toDate().getTime();
      this.actLogSrv.get7DaysActionLog(this.query, this.page, this.size).subscribe(rep => {
        this.actionLogDBSource.data = rep.body['content']
        this.actionLogDBSource.data.forEach(actionLog => {
          actionLog.time = new Date(actionLog.timeStamp)
        })
        this.totalCount = rep.body['totalElements']
      })
    }
  }

  detail(actionLog) {
    this.actionDialog.open(ActionlogDialogComponent, {
      data: actionLog
    })
  }

  pageChanged(event) {
    this.page = event.pageIndex
    this.size = event.pageSize
    this.refresh()
  }

  search() {
    this.paginator.firstPage()
    this.refresh()
  }

  clear() {
    this.paginator.firstPage()
    this.keyword = ""
    this.selected = { startDate: moment().subtract(6, 'days'), endDate: moment() }
    this.refresh();
  }

}
