import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Attribute } from './attribute';

@Injectable({
  providedIn: 'root'
})
export class AttributeService {

  constructor(private http: HttpClient) { }

  public getAttributesByStatus(status: string, page: number, size: number) {
    return this.http.get<Attribute[]>(environment.serverUrl + "/public/technicalAttribute/status/" + status + "?page=" + page + "&size=" + size, { observe: "response" })
  }

  public getAllAttribute(){
    return this.http.get<Attribute[]>(environment.serverUrl + "/public/technicalAttribute?page=0&size=999" , {observe:"body"})
  }

  public create(attribute: Attribute, language: string) {
    switch (language) {
      case "zh_TW":
        return this.http.post<Attribute>(environment.serverUrl + "/super/technicalAttribute", { "title": { "zh_TW": attribute.title.get(language) }, "status": attribute.status }, { observe: 'response' });
      case "en_US":
        return this.http.post<Attribute>(environment.serverUrl + "/super/technicalAttribute", { "title": { "en_US": attribute.title.get(language) }, "status": attribute.status }, { observe: 'response' });
    }
  }

  public update(attribute: Attribute) {
    return this.http.put<Attribute>(environment.serverUrl + "/super/technicalAttribute", attribute, { observe: 'response' });
  }

  public delete(ids: string[]) {
    return this.http.request("delete", environment.serverUrl + "/super/technicalAttribute", { body: ids, observe: "response" });
  }

  public search(status: string, keyword: string) {
    return this.http.get<Attribute[]>(environment.serverUrl + "/public/technicalAttribute/status/" + status + "/language/zh_TW/titleValue/" + keyword, { observe: 'response' });
  }

  public getAttributeById(attributeId: string) {
    return this.http.get<Attribute>(environment.serverUrl + "/public/technicalAttribute/" + attributeId, { observe: 'response' });
  }

  public uploadIcon(attribute: Attribute, data: FormData) {
    return this.http.post(environment.serverUrl + "/super/technicalAttribute/"+attribute.id+"/language/zh_TW/upload/icon" , data, { observe: "response" })
  }

  public deleteIcon(attribute: Attribute){
    return this.http.request("delete",environment.serverUrl+"/super/technicalAttribute/"+attribute.id+"/language/zh_TW/icon", {observe:"response"})
  }

}
