export class Product {
    id: string
    status: string
    title: Map<string, string>
    titlePictureIds: Map<string,string>
    content: Map<string, string>
    contentPictureIds: Map<string,Array<string>>
    urls: Map<string,Array<Url>>
    technicalAttributeIds: Array<string>
    priority: number
}

export class Url {
    label: string
    value: string
}