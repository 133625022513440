import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Category } from './category';
import { Product } from '../products/product';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  dataChange = new ReplaySubject<FileNode[]>(0);
  productIds: string[] = []
  MappingTable = new Map<String, Product>();
  filenode: FileNode[] = []
  categories: Category[];

  constructor(private http: HttpClient) {  }

  initialize(){ this.dataChange.next(this.filenode)  }

  public getFileNodeByStatus(status: string, page, size) {
    this.filenode = []
    this.getCategoryByStatus(status,page,size)
    .subscribe( rep =>{
      this.categories = rep.body['content']
        this.getAllProductsFromCategries(this.categories)
    })
  }

  public getFileNodeBySearch(status: string, keyword: string, page: number, size: number){
    this.filenode = []
    this.search(status,keyword,page,size).subscribe(
      rep =>{
        this.categories = rep.body['content']
        this.getAllProductsFromCategries(this.categories)
      }
    )
  }

  public getCategoryByStatus(status: string, page, size){
    return this.http.get<Category[]>(environment.serverUrl + "/public/productCategory/status/" + status + "?page=" + page + "&size=" + size + "&sort=priority,asc", { observe: "response" })
  }

  public getAllCategory(){
    return this.http.get<Category[]>(environment.serverUrl + "/public/productCategory",{observe:"body"})
  }

  public getCategoryById(id: string) {
    return this.http.get<Category>(environment.serverUrl + "/public/productCategory/" + id, { observe: "response" })
  }

  public changeSort(categories: Category[]) {
    return this.http.put(environment.serverUrl + "/super/productCategory/priority", categories, { observe: "response" })
  }

  public create(category: Category, language: string) {
    switch (language) {
      case "zh_TW":
        return this.http.post<Category>(environment.serverUrl + "/super/productCategory",
          {
            "title": { "zh_TW": category.title.get(language) },
            "status": category.status,
            "technicalAttributeIds": category.technicalAttributeIds,
            "productIds": category.productIds
          },
          { observe: "response" })
      case "en_US":
        return this.http.post<Category>(environment.serverUrl + "/super/productCategory",
          {
            "title": { "en_US": category.title.get(language) },
            "status": category.status,
            "technicalAttributeIds": category.technicalAttributeIds,
            "productIds": category.productIds
          },
          { observe: "response" })
      default:
        throw Error("語系錯誤，請確認語系為中文或英文")
    }
  }

  public update(category: Category) {
    return this.http.put<Category>(environment.serverUrl + "/super/productCategory", category, { observe: "response" })
  }

  public delete(ids: string[]) {
    return this.http.request("delete", environment.serverUrl + "/super/productCategory", { body: ids, observe: "response" })
  }

  public search(status: string, keyword: string, page: number, size: number) {
    return this.http.get<any>(environment.serverUrl + "/public/productCategory/status/" + status + "/language/zh_TW/titleValue/" + keyword + "?page=" + page + "&size=" + size, { observe: "response" })
  }

  public uploadIconImg(category: Category, img: FormData, language: string) {
    return this.http.post(environment.serverUrl + "/super/productCategory/" + category.id + "/language/" + language + "/upload/icon", img, { observe: "response" })
  }

  public getMappingTable(productIds: string[]) {
    return this.http.post<Map<string,Product>>(environment.serverUrl + "/public/product/MappingTable", productIds, { observe: "response" })
  }

  getAllProductsFromCategries(data: Category[]) {
    if(data.length == 0){this.initialize() ;return}
    let temp = []
    data.forEach(item => { temp.push(item.productIds) })
    this.productIds = temp.reduce((arr, element) => { return arr.concat(element) })
    this.getMappingTable(this.productIds).subscribe(rep => {
      let stock = rep.body;
      Object.keys(stock).forEach(key => { const value: Product = stock[key]; this.MappingTable.set(key, value) })
      this.categories.forEach(category => { this.filenode.push(this.buildFileTree(category))
        this.initialize()
      })
    })

  }

  buildFileTree(obj: Category): FileNode {
    if (obj == null) return;
    const node = new FileNode()
    node.id = obj.id
    node.filename = obj.title['zh_TW']

    if (obj.productIds) {
      node.type = "Category"
      obj.productIds.forEach(id => this.buildChildNode(id, node))
    }

    return node
  }

  buildChildNode(id: string, parent: FileNode) {
    const node = new FileNode()
    node.filename = this.MappingTable.get(id).title['zh_TW']
    if(this.MappingTable.get(id).status == "INACTIVE"){
      node.filename = "(停用) "+node.filename
    }
    node.id = id
    node.type = "Product"
    parent.children.push(node)
  }

}


export class FileNode {
  filename: string
  id: string
  type: string
  children: FileNode[] = []
}
