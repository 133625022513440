export class Keyword {
    id: string
    keyword: string
    keywordValues: KeywordItem[]
    description: string
    userChangeable: boolean
}

export class KeywordItem {
    constructor(i:number){
        this.sequence = i
    }
    sequence: number
    label: string
    value: string
    description: string
}