import { Component, OnInit } from '@angular/core';
import { AccountService } from './account.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Account } from './account';
import { ToastrService } from 'ngx-toastr';
import  * as encrypt  from 'js-sha512';

@Component({
  selector: 'app-account-edit',
  templateUrl: './account-edit.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountEditComponent implements OnInit {

  userId: string;
  account: Account = new Account();
  isNew: boolean = true;
  password: string;
  confirmpwd: string;

  constructor(private accSrv: AccountService, private router: Router, private route: ActivatedRoute, private toastrSrv: ToastrService) { }

  ngOnInit() {
    this.route.params.subscribe(param => {
      this.userId = param['userId']
    })
    if (this.userId === "null") {
      this.account.status = "ACTIVE"
      this.isNew = true;
    } else {
      this.accSrv.getAccountById(this.userId).subscribe(rep => {
        this.account = rep.body
        this.account.password = null;
        this.isNew = false
      })
    }
  }

  save() {
    if (this.isNew) {
      if (this.checkNull()) {
        this.account.role = "PLATFORM_ADMIN";
        this.account.tenantId = "CyberStar";
        this.accSrv.create(this.account).subscribe(rep => {
          if (rep.status == 200) {
            this.toastrSrv.success("新增 " + this.account.fullName + " 成功", "Success")
            this.isNew = false
          }
        })
      }
    } else {
      if (this.checkNull()) {
        this.accSrv.update(this.account).subscribe(rep => {
          if (rep.status == 200) {
            this.toastrSrv.success("修改 " + this.account.fullName + " 成功", "Success")
          }
        })
      }
    }
  }

  cancel() {
    history.go(-1);
    this.router.navigate(['/dashboard', 'account'])
  }

  checkNull(): boolean {
    if (this.password && this.confirmpwd) {
      if (this.password === this.confirmpwd) {
        this.account.password = encrypt.sha512(this.password);
        return true;
      }else{
        this.toastrSrv.info('密碼不符，請重新輸入',"提醒")
        return false;
      }
    }
    if (this.isNew) {
      if (this.account.userId && this.account.email && this.account.fullName && this.password && this.confirmpwd) {
        return true
      }
    } else {
      if (this.account.userId && this.account.email && this.account.fullName) {
        return true
      }
    }
    this.toastrSrv.info('必填欄位不可為空',"提醒")
  }

}
