import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Product } from './product';
import { Category } from '../product-categories/category';


@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private http: HttpClient) { }

  public getProductByStatus(status: string, page, size) {
    return this.http.get<Product[]>(environment.serverUrl + "/public/product/status/" + status + "?page=" + page + "&size=" + size + "&sort=priority,asc", { observe: "response" })
  }

  public getAllProduct() {
    return this.http.get<Product[]>(environment.serverUrl + "/public/product", { observe: "body" })
  }

  public getProductById(id: string) {
    return this.http.get<Product>(environment.serverUrl + "/public/product/" + id, { observe: "response" })
  }

  public getCategoryByProductId(id: string) {
    return this.http.get<Category[]>(environment.serverUrl + "/public/productCategory/product/" + id, { observe: "response" })
  }

  public changeSort(products: Product[]) {
    return this.http.put(environment.serverUrl + "/super/product/priority", products, { observe: "response" })
  }

  public create(product: Product, language: string) {
    switch (language) {
      case "zh_TW":
        return this.http.post<Product>(environment.serverUrl + "/super/product",
          {
            "title": { "zh_TW": product.title.get(language) },
            "content": { "zh_TW": product.content.get(language) },
            "status": product.status,
            "technicalAttributeIds": product.technicalAttributeIds
          },
          { observe: "response" })
      case "en_US":
        return this.http.post<Product>(environment.serverUrl + "/super/product",
          {
            "title": { "en_US": product.title.get(language) },
            "content": { "en_US": product.content.get(language) },
            "status": product.status,
            "technicalAttributeIds": product.technicalAttributeIds
          },
          { observe: "response" })
      default:
        throw Error("語系錯誤，請確認語系為中文或英文")
    }
  }

  public update(product: Product) {
    return this.http.put<Product>(environment.serverUrl + "/super/product", product, { observe: "response" })
  }

  public delete(ids: string[]) {
    return this.http.request("delete", environment.serverUrl + "/super/product", { body: ids, observe: "response" })
  }

  public search(status: string, keyword: string, page: number, size: number) {
    return this.http.get<Product[]>(environment.serverUrl + "/public/product/status/" + status + "/language/zh_TW/titleValue/" + keyword + "?page=" + page + "&size=" + size, { observe: "response" })
  }

  public uploadTitleImg(product: Product, img: FormData, language: string) {
    return this.http.post(environment.serverUrl + "/super/product/" + product.id + "/language/" + language + "/upload/titlePicture", img, { observe: "response" })
  }

  public uploadContentImg(product: Product, img: FormData, language: string) {
    return this.http.post<Product>(environment.serverUrl + "/super/product/" + product.id + "/language/" + language + "/upload/contentPicture", img, { observe: "response" })
  }

  public deleteContentImg(product: Product, id : string, language: string){
    return this.http.request("delete",environment.serverUrl + "/super/product/"+product.id+"/language/"+language+"/contentPicture",{body:id,observe:"response"})
  }
}
