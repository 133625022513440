export class ArticleCategory {

    constructor(){
        this.color = "#fff"
    }

    id: string;
    color: string;
    title: Map<string, string>
    articleIds: string[]
}
