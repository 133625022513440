import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Keyword } from './keyword';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class KeywordService {

  constructor(private http: HttpClient) { }

  public getAllKeywords(){
    return this.http.get<Keyword[]>(environment.serverUrl+"/super/keyword/all",{observe:"response"})
  }

  public create(){
    return this.http.post<Keyword>(environment.serverUrl+"/super/keyword",{observe:"response"})
  }

  public search(searchText:string){
    return this.http.get<Keyword[]>(environment.serverUrl+"/super/keyword/"+searchText,{observe:"response"})
  }

  public update(keyword:Keyword){
    return this.http.put<Keyword>(environment.serverUrl+"/super/keyword",keyword,{observe:"response"})
  }
}
