import { ArticleCategoryService } from './article-category.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatDialog, MatPaginatorIntl, MatPaginator } from '@angular/material';
import { ArticleCategory } from './article-category';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DeleteDialogComponent } from 'src/app/global/delete-dialog/delete-dialog.component';
import { SortDialogComponent } from 'src/app/global/sort-dialog/sort-dialog.component';
import { ArticleCategoryEditDialogComponent } from './article-category-edit-dialog/article-category-edit-dialog.component';

@Component({
  selector: 'app-article-category',
  templateUrl: './article-category.component.html',
  styleUrls: ['./article-category.component.css']
})
export class ArticleCategoryComponent implements OnInit {

  keyword: string;
  articleCategoryDBSource = new MatTableDataSource<ArticleCategory>();
  displayedColumns = ['name', 'action']
  totalCount: number

  @ViewChild('paginator') paginator: MatPaginator

  constructor(private articleCategorySrv: ArticleCategoryService, private router: Router,
    private toastrSrv: ToastrService, private dialog: MatDialog, private matPaginatorIntl: MatPaginatorIntl) { }

  ngOnInit() {
    this.matPaginatorIntl.itemsPerPageLabel = '每頁筆數：';
    this.matPaginatorIntl.nextPageLabel = '下一頁';
    this.matPaginatorIntl.previousPageLabel = '上一頁';
    this.matPaginatorIntl.firstPageLabel = '第一頁'
    this.matPaginatorIntl.lastPageLabel = '最後一頁'
    this.buildData()
  }

  buildData() {
    if (this.keyword) {
      this.articleCategorySrv.search(this.keyword).subscribe(rep => this.setData(rep))
    } else {
      this.articleCategorySrv.getAllArticleCategories().subscribe(rep => this.setData(rep))
    }
  }

  setData(rep: any) {
    if(rep){
      this.articleCategoryDBSource.data = rep
      this.totalCount = rep.length
    }
  }

  openEdit(id:string) {
    const dialogRef = this.dialog.open( ArticleCategoryEditDialogComponent , {data: id === "isNew" ? "newArticleCategory" : id , disableClose:true} )
    dialogRef.afterClosed().subscribe(rep => {
      if(rep == "update") this.buildData()
    })
  }

  delete(articleCategory: ArticleCategory) {
    let temp = []
    temp.push(articleCategory.id)
    const dialogRef = this.dialog.open(DeleteDialogComponent, { disableClose: true })
    dialogRef.afterClosed().subscribe(rep => {
      if (rep == "Yes") {
        this.articleCategorySrv.delete(temp).subscribe(rep => {
          if (rep.status == 200) {
            this.toastrSrv.success('刪除 ' + articleCategory.title['zh_TW'] + ' 成功', "success")
            this.afterDelete()
          }
        })
      }
    })
  }

  afterDelete() {
    if ((this.totalCount % 10) === 1 && this.totalCount != 1) {
      this.paginator.previousPage()
    } else {
      this.buildData()
    }
  }

  changeTab() {
    this.keyword = ""
    this.buildData()
  }

  search() {
    this.paginator.firstPage();
    this.buildData();
  }

  clear() {
    this.paginator.firstPage();
    this.keyword = ""
    this.buildData()
  }

  pageChanged(event) {
    this.buildData()
  }

}
