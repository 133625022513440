import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { Attribute } from './attribute';
import { AttributeService } from './attribute.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment.prod';

@Component({
  selector: 'app-attribute-edit',
  templateUrl: './attribute-edit.component.html',
  styleUrls: ['../products/products.component.css']
})
export class AttributeEditComponent implements OnInit {

  id: string
  name: string
  attribute = new Attribute();
  selectedLanguage: string
  isNew = false
  url: SafeResourceUrl = ""
  selectedFile: File = null
  target = environment.serverUrl+"/public/file/"

  constructor(private attributeSrv: AttributeService, private router: Router,
    private toastrSrv: ToastrService, private route: ActivatedRoute,private sanitizer:DomSanitizer) { }

  ngOnInit() {
    this.selectedLanguage = "zh_TW"
    this.route.params.subscribe(param => { this.id = param['id'] })
    if (this.id === "null") {
      this.attribute.status = "ACTIVE"
      this.attribute.title = new Map()
      this.isNew = true
      this.url = ""
    } else {
      this.attributeSrv.getAttributeById(this.id).subscribe(rep => {
        this.attribute = rep.body
        this.name = this.attribute.title[this.selectedLanguage]
        this.isNew = false
        if (this.attribute.iconIds) {
          this.url = this.target + this.attribute.iconIds[this.selectedLanguage]
        }
      })
    }
  }

  save() {
    if (this.isNew) {
      if (this.checkNull()) {
        this.attributeSrv.create(this.attribute, this.selectedLanguage).subscribe(rep => {
          if (rep.status == 200) {
            this.attribute = rep.body
            this.isNew = false
            const fd = new FormData()
            fd.set("file", this.selectedFile)
            this.attributeSrv.uploadIcon(this.attribute, fd).subscribe(() => {
              this.toastrSrv.success("新增 " + this.attribute.title[this.selectedLanguage] + " 成功", "Success")
            })
          }
        })
      } 
    } else {
      if (this.checkNull()) {
        this.attributeSrv.update(this.attribute).subscribe(rep => {
          if (this.selectedFile) {
            const fd = new FormData()
            fd.set("file", this.selectedFile)
            this.attributeSrv.uploadIcon(this.attribute, fd).subscribe(() => {
              this.selectedFile = null;
              this.toastrSrv.success("修改 " + this.attribute.title[this.selectedLanguage] + " 成功", "Success")
            })
          } else {
            this.toastrSrv.success("修改 " + this.attribute.title[this.selectedLanguage] + " 成功", "Success")
          }
        })
      }
    }
  }

  cancel() {
    history.go(-1);
    this.router.navigate(['/dashboard', 'attributes'])
  }

  checkNull(): boolean {
    if (this.isNew) {
      if (this.selectedFile && this.name) {
        this.attribute.title.set(this.selectedLanguage, this.name)
        return true
      }
    } else {
      if (this.name) {
        this.attribute.title[this.selectedLanguage] = this.name;
        return true
      }
    }
    this.toastrSrv.info("必填欄位不可為空!", "提醒")
    return false
  }

  fileUpload(event) {
    this.selectedFile = <File>event.target.files[0]
    if (this.selectedFile) {
      let reader = new FileReader();
      reader.onload = (e: any) => {
        this.url = this.sanitizer.bypassSecurityTrustResourceUrl(e.target.result)
      }
      reader.readAsDataURL(this.selectedFile)
    }
  }

  changeLanguage() {
    this.name = this.attribute.title[this.selectedLanguage]
  }

}
