import { Component, OnInit, ViewChild } from '@angular/core';
import { ProductService } from './product.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Product } from './product';
import { Category } from '../product-categories/category';
import { Attribute } from '../attributes/attribute';
import { AttributeService } from '../attributes/attribute.service';
import { MatSelect } from '@angular/material';
import { DndDropEvent, DropEffect } from 'ngx-drag-drop';
import { FormControl } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-product-edit',
  templateUrl: './product-edit.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductEditComponent implements OnInit {

  id: string
  type: string
  name: string
  content: string
  isNew = false
  _product = new Product()

  titleImage: File = null
  titleImgSrc = ""
  // contentImage: File[] = []
  // contentImgSrc = []
  contentImage: File = null
  contentImgSrc = ""
  target = environment.serverUrl+"/public/file/"

  selectedLanguage: string
  selectedAttributeId: string[] = []
  attributeList: Attribute[] = []
  belongCategory: Category[] = []

  attributeMultiCtrl: FormControl = new FormControl()
  attributeFilterCtrl: FormControl = new FormControl()
  filteredAttributeMulti: ReplaySubject<Attribute[]> = new ReplaySubject()
  private _onDestroy = new Subject<void>();
  @ViewChild('attribute') matSelect: MatSelect

  constructor(private productSrv: ProductService, private router: Router,
    private route: ActivatedRoute, private toastrSrv: ToastrService,
    private attributeSrv: AttributeService) {

  }

  ngOnInit() {
    this.selectedLanguage = "zh_TW"
    this.route.params.subscribe(param => { this.id = param.id; this.type = param.type })
    if (this.id === "null") {
      this.isNew = true
      this._product.status = "ACTIVE"
      this._product.title = new Map()
      this._product.content = new Map()
      this.titleImgSrc = ""
      this.contentImgSrc = ""
      this.getAttribute()
    } else {
      this.productSrv.getProductById(this.id).subscribe(rep => {
        this._product = rep.body
        this.productSrv.getCategoryByProductId(this._product.id).subscribe(rep => { this.belongCategory = rep.body;})
        this.getAttribute()
      })
    }
  }

  //取得技術屬性列表
  getAttribute() {
    this.attributeSrv.getAllAttribute().subscribe(rep => {
      this.attributeList = rep['content'];
      this.attributeList.forEach(item => {
        if(item.status == "INACTIVE"){item.title[this.selectedLanguage]="(停用) "+item.title[this.selectedLanguage]}
        item['disable'] = false
        item['effectAllowed'] = "copyMove"
      })
      this.filteredAttributeMulti.next(this.attributeList.slice())
      this.attributeFilterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => { this.filterAttributesMulti(); })
      this.setValue()
    })
  }

  //設定畫面內容
  setValue() {
    if (!this.isNew) {
      let temp = []
      this.name = this._product.title[this.selectedLanguage]
      this.content = this._product.content[this.selectedLanguage]
      this._product.technicalAttributeIds.forEach(id => {
        temp.push(this.attributeList.filter(item => item.id == id)[0])
      })
      this.matSelect.value = temp
      this.attributeMultiCtrl.setValue(this.matSelect.value)
      this.setAttributeId()
      if (this._product.titlePictureIds) { this.titleImgSrc = this.target + this._product.titlePictureIds[this.selectedLanguage] }
      if (this.checkDatabaseContentImageExist()){
          this.contentImgSrc = this.target + this._product.contentPictureIds[this.selectedLanguage][0]
      }
      // if(this._product.contentPictureIds.size != 0 && this._product.contentPictureIds[this.selectedLanguage]){
      //   if (this._product.contentPictureIds[this.selectedLanguage].length != 0) { 
      //     for (let i = 0 ; i<this._product.contentPictureIds[this.selectedLanguage].length ; i++){
      //       this.contentImgSrc.push("http://192.168.3.22/public/file/"+this._product.contentPictureIds[this.selectedLanguage][i])
      //     }
      //    }
      // }
    }
  }

  //存檔
  save() {
    if (this.isNew) {
      if (this.checkNull()) {
        this.productSrv.create(this._product, this.selectedLanguage).subscribe(rep => {
          if (rep.status == 200) {
            this._product = rep.body
            this.imageUploadToDatabase()
            this.isNew = false
          }
        })
      }
    } else {
      if (this.checkNull()) {
        this.productSrv.update(this._product).subscribe(rep => {
          if (rep.status == 200) {
            this.updateDatabaseImage()
            this.toastrSrv.success("修改 " + this._product.title[this.selectedLanguage] + " 成功", "success")
          }
        })
      }
    }
  }

  //上傳圖片至DB
  imageUploadToDatabase() {
      const fd = new FormData()
      fd.set('file',this.titleImage)
      this.productSrv.uploadTitleImg(this._product,fd,this.selectedLanguage).toPromise().then(rep=>{
        if(rep.status==200){
          if(this.contentImage && this.contentImgSrc){
            fd.set('file',this.contentImage)
            this.productSrv.uploadContentImg(this._product,fd,this.selectedLanguage).subscribe(rep=>{if(rep.status==200){this.toastrSrv.success("新增 " + this._product.title[this.selectedLanguage] + " 成功", "success")}})
          }else{
            this.toastrSrv.success("新增 " + this._product.title[this.selectedLanguage] + " 成功", "success")
          }
        }
      })
    }

    // if (this.isNew) {
    //   if (this.contentImage) {
    //     const fd = new FormData()
    //     for(let i = 0 ; i<this.contentImage.length ; i++){
    //       fd.append("file",this.contentImage[i])
    //     }
    //     this.productSrv.uploadContentImg(this._product, fd, this.selectedLanguage).toPromise().then((rep)=>{
    //       this.contentImage = null;
    //       fd.set('file',this.titleImage)
    //       this.productSrv.uploadTitleImg(this._product, fd, this.selectedLanguage).subscribe((rep)=>{
    //         if(rep.status == 200){this.toastrSrv.success("新增 " + this._product.title[this.selectedLanguage] + " 成功", "success")}
    //       })
    //     })
    //   }else{
    //     const fd = new FormData()
    //     fd.set("file", this.titleImage)
    //     this.productSrv.uploadTitleImg(this._product, fd, this.selectedLanguage).subscribe((rep) => {
    //       if(rep.status==200){this.toastrSrv.success("新增 " + this._product.title[this.selectedLanguage] + " 成功", "success");this.titleImage=null}
    //     })
    //   }
    // } else {
    //   if (this.contentImage) {
    //     const fd = new FormData()
    //     for(let i = 0 ; i<this.contentImage.length ; i++){
    //       fd.append("file",this.contentImage[i])
    //     }
    //     this.productSrv.uploadContentImg(this._product, fd, this.selectedLanguage).subscribe(() => { this.contentImage = null })
    //   }
    //   if (this.titleImage) {
    //     const fd = new FormData()
    //     fd.set("file", this.titleImage)
    //     this.productSrv.uploadTitleImg(this._product, fd, this.selectedLanguage).subscribe()
    //   }
    // }

  updateDatabaseImage(){
    //如標題圖片不為空，變更標題圖片
    if(this.titleImage){
      const fd = new FormData()
      fd.set('file',this.titleImage)
      this.productSrv.uploadTitleImg(this._product,fd,this.selectedLanguage).subscribe()
    }
    //如內文網址及內文圖片皆空
    if(!this.contentImgSrc && !this.contentImage) {
      //且產品的內文圖片ID不為空，刪除圖片
      if(this.checkDatabaseContentImageExist()){
        this.productSrv.deleteContentImg(this._product,this._product.contentPictureIds[this.selectedLanguage],this.selectedLanguage).subscribe(rep=>{if(rep.status==200){this._product.contentPictureIds[this.selectedLanguage]=null}})
      }
    }
    //如內文圖片不為空，變更內文圖片
    if(this.contentImage){
      //判斷是否有舊圖片，若有刪除再新增
      if(this.checkDatabaseContentImageExist()){
        this.productSrv.deleteContentImg(this._product,this._product.contentPictureIds[this.selectedLanguage],this.selectedLanguage).toPromise().then(rep=>{
          if(rep.status==200){
            const fd = new FormData()
            fd.set("file",this.contentImage)
            this.productSrv.uploadContentImg(this._product,fd,this.selectedLanguage).subscribe(rep=>{if(rep.status==200)this._product= rep.body})
          }
        })
      }else{ //若無直接新增
        const fd = new FormData()
        fd.set("file",this.contentImage)
        this.productSrv.uploadContentImg(this._product,fd,this.selectedLanguage).subscribe(rep=>{if(rep.status==200)this._product= rep.body})
      }
    }
  }

  checkDatabaseContentImageExist():boolean{
    if(this._product.contentPictureIds.size!=0 && this._product.contentPictureIds[this.selectedLanguage]){
      if(this._product.contentPictureIds[this.selectedLanguage].length !=0 ){
        return true;
      }
    }
    return false;
  }

  //檢查欄位
  checkNull(): boolean {
    if (this.isNew) {
      if (this.name && this.content && this.titleImage) {
        if (this.selectedAttributeId) this._product.technicalAttributeIds = this.selectedAttributeId
        this._product.title.set(this.selectedLanguage, this.name)
        this._product.content.set(this.selectedLanguage, this.content)
        return true
      }
    } else {
      if (this.name && this.content) {
        if (this.selectedAttributeId) this._product.technicalAttributeIds = this.selectedAttributeId
        this._product.title[this.selectedLanguage] = this.name
        this._product.content[this.selectedLanguage] = this.content
        return true
      }
    }
    this.toastrSrv.info("必填欄位不可為空!", "提醒")
    return false
  }

  //回到上一頁
  cancel() {
    history.go(-1)
    if (this.type == 'product') {
      this.router.navigate(['/dashboard', 'products'])
    } else if (this.type == 'category') {
      this.router.navigate(['/dashboard', 'categories'])
    }
  }

  // 拖拉用的方法
  onDragged(item: any, list: any[], effect: DropEffect) {
    if (effect === "move") {
      const index = list.indexOf(item);
      list.splice(index, 1);
    }
  }

  // 拖拉用的方法
  onDragEnd(event: DragEvent) {
    this.setAttributeId()
  }

  // 拖拉用的方法
  onDrop(event: DndDropEvent, list?: any[]) {
    if (list && (event.dropEffect === "copy" || event.dropEffect === "move")) {
      let index = event.index;
      if (typeof index === "undefined") {
        index = list.length;
      }
      list.splice(index, 0, event.data);
    }
  }

  // 移除標籤
  removeAttributeTag(attribute: Attribute) {
    this.matSelect.value = this.matSelect.value.filter(tag => tag !== attribute);
    this.setAttributeId()
  }

  //上傳標題圖片及顯示
  titleUpload(event) {
    this.titleImage = <File>event.target.files[0]
    if (this.titleImage) {
      let reader = new FileReader();
      reader.onload = (e: any) => {
        this.titleImgSrc = e.target.result
      }
      reader.readAsDataURL(this.titleImage)
    }
  }

  //上傳內文圖片及顯示
  contentUpload(event) {
    this.contentImage = <File>event.target.files[0]
    if (this.contentImage) {
      let reader = new FileReader();
      reader.onload = (e: any) => {
        this.contentImgSrc = e.target.result
      }
      reader.readAsDataURL(this.contentImage)
    }
    // if(event.target.files && event.target.files[0]){
    //   var filesAmount = event.target.files.length;
    //   for (let i = 0 ; i< filesAmount ; i++){
    //     let reader = new FileReader();
    //     this.contentImage.push(event.target.files[i])
    //     reader.onload = (event: any)=>{
    //       this.contentImgSrc.push(event.target.result)
    //     }
    //       reader.readAsDataURL(event.target.files[i])
    //   }
    // }
  }

  deleteImage(){
      this.contentImgSrc = ""
      this.contentImage = null
  }

  //選單過濾使用之方法
  filterAttributesMulti() {
    if (!this.attributeList) {
      return;
    }
    let search = this.attributeFilterCtrl.value
    if (!search) {
      this.filteredAttributeMulti.next(this.attributeList.slice())
      return
    } else {
      search = search.toLowerCase();
    }
    this.filteredAttributeMulti.next(
      this.attributeList.filter(attribute => attribute.title[this.selectedLanguage].toLowerCase().indexOf(search) > -1))
  }

  //設定所選取的AttributeId
  setAttributeId() {
    let temp = []
    this.matSelect.value.forEach(item => temp.push(item.id))
    this.selectedAttributeId = temp
  }

}
