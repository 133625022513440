import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatTableDataSource } from '@angular/material';
import { Keyword, KeywordItem } from './keyword';
import { ToastrService } from 'ngx-toastr';
import { KeywordService } from './keyword.service';

@Component({
  selector: 'app-keyword-dialog',
  templateUrl: './keyword-dialog.component.html',
  styleUrls: ['./keyword.component.css']
})
export class KeywordDialogComponent implements OnInit {
  keyword: Keyword
  keywordItems: KeywordItem[]
  displayedColumns = ['sequence', 'label', 'value']
  constructor(@Inject(MAT_DIALOG_DATA) private data: any, private toastrSrv: ToastrService,
    private keywordSrv: KeywordService) { }

  ngOnInit() {
    this.keyword = this.data
    this.keywordItems = this.keyword.keywordValues
  }

  save() {
    if (this.checkNull()) {
     this.keywordSrv.update(this.keyword).subscribe(rep=>{
       if( rep.status == 200){
         this.toastrSrv.success("修改 "+ this.keyword.keyword +" 成功", "Success")
       }
     })
    }
  }

  checkNull(): boolean {
    if (this.keywordItems) {
      const set = new Set();
      const result = this.keywordItems.filter(item => !set.has(item.sequence) ? set.add(item.sequence) : false)
      if (result.length != this.keywordItems.length) {
        this.toastrSrv.info("序號重複，請確認並重新輸入", "提醒")
        return false;
      }
      this.keyword.keywordValues = this.keywordItems
    }
    if (this.keyword.description) {
      return true
    }
    return false
  }


  newKeywordItem() {
    if (this.keywordItems) {
      this.keywordItems.push(new KeywordItem(this.keywordItems.length + 1))
    } else {
      this.keywordItems = []
      this.keywordItems.push(new KeywordItem(1))
    }
  }

  remove(keywordItem) {
    this.changeSort(this.keywordItems.indexOf(keywordItem))
    this.keywordItems.splice(this.keywordItems.indexOf(keywordItem), 1)
  }

  changeSort(position) {
    for (let i = position + 1; i < this.keywordItems.length; i++) {
      this.keywordItems[i].sequence--
    }
  }

}
