import { Component, OnInit } from '@angular/core';
import { AuthorizationService } from '../authorization/authorization.service';
import { Router } from '@angular/router';
import { GlobalService } from '../global/global.service';
import { ToastrService } from 'ngx-toastr';
import * as encrypt from 'js-sha512';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  userId : string
  password : string

  constructor(private authSrv : AuthorizationService, private router : Router,
              private globalSrv : GlobalService,private toastrSrv : ToastrService) { }

  ngOnInit() {
    this.initInput()
  }

  login(){
    if(this.userId && this.password){
      let encryptPWD = encrypt.sha512(this.password);
      this.authSrv.fetchAccessToken(this.userId,encryptPWD).subscribe(authInfo => {
        this.globalSrv.authInfo = authInfo;
        return this.router.navigate(['/dashboard']);
      })
    }else{
      this.toastrSrv.info('帳號及密碼不可為空','提醒')
    }
  }

  userIdKeyUpEnter() {
    var passInput = document.getElementById("password");
    passInput.focus();
  }
  
  passwordKeyUpEnter() {
    this.login();
  }

  initInput(){
    this.userId=""
    this.password=""
  }
}
