import { Component, OnInit } from '@angular/core';
import { MatDialog, MatTreeNestedDataSource, } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { CategoryService, FileNode } from './category.service';
import { TypeDialogComponent } from '../type-dialog/type-dialog.component';
import { NestedTreeControl } from '@angular/cdk/tree';
import { DeleteDialogComponent } from '../../global/delete-dialog/delete-dialog.component';
import { SortDialogComponent } from '../../global/sort-dialog/sort-dialog.component';

@Component({
  selector: 'app-product-categories',
  templateUrl: './product-categories.component.html',
  styleUrls: ['../products/products.component.css']
})
export class ProductCategoriesComponent implements OnInit {
  id: string
  status: string
  keyword: string
  tabIndex: number = 0

  nestedTreeControl: NestedTreeControl<FileNode>;
  nestedDataSource: MatTreeNestedDataSource<FileNode>;
  hasNestedChild = (_: number, nodeData: FileNode) => { return nodeData.type === "Category" };
  private _getChildren = (node: FileNode) => node.children;

  constructor(private categorySrv: CategoryService, private router: Router,
    private toastrSrv: ToastrService, private dialog: MatDialog) {
    this.nestedTreeControl = new NestedTreeControl(this._getChildren)
    this.nestedDataSource = new MatTreeNestedDataSource();
    this.categorySrv.dataChange.subscribe(data => this.nestedDataSource.data = data)
  }


  ngOnInit() {
    this.tabIndex = 0
    this.refresh()
  }

  refresh() {
    if (this.tabIndex == 0) {
      this.status = "ACTIVE"
      if (this.keyword) this.categorySrv.getFileNodeBySearch(this.status, this.keyword, 0, 100)
      else this.categorySrv.getFileNodeByStatus(this.status, 0, 100)
    } else if (this.tabIndex == 1) {
      this.status = "INACTIVE"
      if (this.keyword) this.categorySrv.getFileNodeBySearch(this.status, this.keyword, 0, 100)
      else this.categorySrv.getFileNodeByStatus(this.status, 0, 100)
    }
  }

  newCategory() {
    const dialogRef = this.dialog.open(TypeDialogComponent)
    dialogRef.afterClosed().subscribe(rep => {
      if(rep) this.router.navigate(['/dashboard', rep, 'null', "category"])
    })
  }

  delete(node: FileNode) {
    let tempIds = []
    tempIds.push(node.id)
    const dialogRef = this.dialog.open(DeleteDialogComponent)
    dialogRef.afterClosed().subscribe(rep => {
      if (rep == "Yes") {
        this.categorySrv.delete(tempIds).subscribe(rep => {
          if (rep.status == 200) { this.toastrSrv.success('刪除' + node.filename + '成功', 'Success'); this.refresh() }
        })
      }
    })
  }

  clear() {
    this.keyword = ""
    this.refresh()
  }

  changeSort() {
    this.dialog.open(SortDialogComponent, { data: "category" })
  }

  changeTab(){
    this.keyword=""
    this.refresh()
  }

}

