export class Story {
    id: string
    status: string
    needShow: boolean = false
    title: Map<string, string>
    content: Map<string, string>
    iconIds: Map<string, string>
    priority: number
}

