import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ActionLogQueryInfo } from './action-log-queryinfo';

@Injectable({
  providedIn: 'root'
})
export class ActionLogService {

  constructor(private http : HttpClient) { }

  public get7DaysActionLog(query : ActionLogQueryInfo,page:number,size:number){
    return this.http.post(environment.serverUrl+"/super/actionlog/interval?page="+page+"&size="+size+"&sort=timeStamp,desc",query,{observe:"response"})
  }

  public searchActionLog(query : ActionLogQueryInfo,page:number,size:number){
    return this.http.post(environment.serverUrl+"/super/actionlog/interval/operator/url?page="+page+"&size="+size+"&sort=timeStamp,desc",query,{observe:"response"})
  }
}
