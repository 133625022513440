import { ArticleService } from './../../article/articles/article.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { DropEffect, DndDropEvent } from 'ngx-drag-drop';
import { ToastrService } from 'ngx-toastr';
import { ProductService } from '../../product/products/product.service';
import { CategoryService } from '../../product/product-categories/category.service';
import { CarouselService } from '../../carousel/carousel.service';
import { StoryService } from '../../story/story.service';


@Component({
  selector: 'app-sort-dialog',
  templateUrl: './sort-dialog.component.html',
  styleUrls: ['./sort-dialog.component.css']
})
export class SortDialogComponent implements OnInit {

  sortData: any[] = []
  type: string
  private currentDragEffectMsg: string;
  private currentDraggableEvent: DragEvent;

  constructor(@Inject(MAT_DIALOG_DATA) private data: any, private toastrSrv: ToastrService,
    private productSrv: ProductService, private categorySrv: CategoryService,
    private carouselSrv: CarouselService, private storySrv: StoryService,
    private articleSrv: ArticleService) { }

  ngOnInit() {
    this.type = this.data
    this.getSortData()
  }

  getSortData() {
    switch (this.type) {
      case "product":
        this.productSrv.getProductByStatus("ACTIVE", 0, 100).subscribe(rep => this.setElseValue(rep))
        break;
      case "category":
        this.categorySrv.getCategoryByStatus("ACTIVE", 0, 100).subscribe(rep => this.setElseValue(rep))
        break;
      case "carousel":
        this.carouselSrv.getCarouselByStatus("ACTIVE", 0, 100).subscribe(rep => this.setElseValue(rep))
        break;
      case "story":
        this.storySrv.getStoriesByStatus("ACTIVE", 0, 100).subscribe(rep => this.setElseValue(rep))
        break;
      case "article":
        this.articleSrv.getArticlesByStatus("ACTIVE", 0, 100).subscribe(rep => this.setElseValue(rep))
    }
  }

  setElseValue(rep) {
    this.sortData = this.type == "article" ? rep['content'] : rep.body['content']
    this.sortData.forEach(item => {
      if (item.title["zh_TW"].length >= 9) { item.title["zh_TW"] = item.title["zh_TW"].substring(0, 9) + "..." }
      item['disable'] = false
      item['effectAllowed'] = "copyMove"
    })
  }

  onDragged(item: any, list: any[], effect: DropEffect) {
    if (effect === "move") {
      const index = list.indexOf(item);
      list.splice(index, 1);
    }
  }

  onDrop(event: DndDropEvent, list?: any[]) {
    if (list
      && (event.dropEffect === "copy"
        || event.dropEffect === "move")) {

      let index = event.index;

      if (typeof index === "undefined") {

        index = list.length;
      }

      list.splice(index, 0, event.data);
    }
  }

  onDragStart(event: DragEvent) {
    this.currentDragEffectMsg = "";
    this.currentDraggableEvent = event;

  }

  onDragEnd(event: DragEvent) {
    for (let i = 0; i < this.sortData.length; i++) {
      this.sortData[i].priority = i
    }
    this.currentDraggableEvent = event;
  }

  save() {
    switch (this.type) {
      case "product":
        this.productSrv.changeSort(this.sortData).subscribe(rep => { if (rep.status == 200) this.toastrSrv.success("更改 產品順序 成功", "Success") })
        break;
      case "category":
        this.categorySrv.changeSort(this.sortData).subscribe(rep => { if (rep.status == 200) this.toastrSrv.success("更改 分類順序 成功", "Success") })
        break;
      case "carousel":
        this.carouselSrv.changeSort(this.sortData).subscribe(rep => { if (rep.status == 200) this.toastrSrv.success("更改 首圖輪播順序 成功", "Success") })
        break;
      case "story":
        this.storySrv.changeSort(this.sortData).subscribe(rep => { if (rep.status == 200) this.toastrSrv.success("更改 成功案例順序 成功", "Success") })
        break;
      case "article":
        this.articleSrv.changeSort(this.sortData).subscribe(rep => { if (rep.status == 200) this.toastrSrv.success("更改 文章管理順序 成功", "Success") })
        break;
      default:
        return;
    }
  }
}
