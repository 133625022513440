import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../global/global.service';
import { AccountService } from '../account/account.service';
import { ToastrService } from 'ngx-toastr';
import * as encrypt from 'js-sha512';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['../account/account.component.css']
})
export class ChangePasswordComponent implements OnInit {

  oldPassword: string
  newPassword: string
  confirmPassword: string

  constructor(private globalSrv: GlobalService, private accSrv: AccountService, private toastrSrv: ToastrService) { }

  ngOnInit() {
    this.oldPassword = "";
    this.newPassword = "";
    this.confirmPassword = "";
  }

  changePassword() {
    if(this.oldPassword && this.newPassword && this.confirmPassword){
      if(this.newPassword == this.confirmPassword){
        let encryptOPWD = encrypt.sha512(this.oldPassword)
        let encryptNPWD = encrypt.sha512(this.newPassword)
        this.accSrv.changePassword(this.globalSrv.authInfo.userId, encryptOPWD, encryptNPWD)
          .subscribe(rep => { if (rep.status == 200) { this.toastrSrv.success("修改密碼成功", "Success") } })
      }
    }
  }

  cancel(){
    this.oldPassword = "";
    this.newPassword = "";
    this.confirmPassword = "";
  }

}
