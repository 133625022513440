import { Component, OnInit } from '@angular/core';
import { Carousel } from './carousel';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CarouselService } from './carousel.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../environments/environment.prod';

@Component({
  selector: 'app-carousel-edit',
  templateUrl: './carousel-edit.component.html',
  styleUrls: ['./carousel.component.css']
})
export class CarouselEditComponent implements OnInit {

  id: string
  name: string
  isNew = false
  carousel = new Carousel();
  selectedLanguage: string

  target = environment.serverUrl+"/public/file/"
  keyVisionImage: File = null
  keyVisionImageSrc : SafeResourceUrl = ""
  backgroundImage: File = null
  backgroundImageSrc = ""

  constructor(private carouselSrv: CarouselService, private router: Router,
    private route: ActivatedRoute, private toastrSrv: ToastrService,
    private sanitizer : DomSanitizer) { }

  ngOnInit() {
    this.selectedLanguage = "zh_TW"
    this.route.params.subscribe(param => this.id = param.id)
    if (this.id === "null") {
      this.isNew = true;
      this.carousel.status = "ACTIVE"
      this.carousel.title = new Map();
    } else {
      this.carouselSrv.getCarouselById(this.id).subscribe(rep => {
        this.carousel = rep.body
        this.name = this.carousel.title[this.selectedLanguage]
        this.keyVisionImageSrc = this.target + this.carousel.keyVisionPictureIds[this.selectedLanguage]
        this.backgroundImageSrc = this.target + this.carousel.backgroundIds[this.selectedLanguage]
      })
    }
  }

  save() {
    if (this.isNew) {
      if (this.checkNull()) {
        this.carouselSrv.create(this.carousel, this.selectedLanguage).subscribe(rep => {
          if (rep.status == 200) {
            this.carousel = rep.body
            this.imageUploadToDatabase(rep.body['id'])
            this.isNew = false
          }
        })
      }
    } else {
      if (this.checkNull()) {
        this.carouselSrv.update(this.carousel).subscribe(rep => {
          if (rep.status == 200) {
            if (this.backgroundImage||this.keyVisionImage) {
              this.imageUploadToDatabase(this.carousel.id)
            }else{
              this.toastrSrv.success("修改 " + this.carousel.title[this.selectedLanguage] + " 成功", "success")
            }
          }
        })
      }
    }
  }

  imageUploadToDatabase(id: string) {
    if (this.isNew) {
      const fd = new FormData()
      fd.set("file", this.keyVisionImage)
      this.carouselSrv.uploadKeyVisionImg(this.carousel, fd, this.selectedLanguage).toPromise().then((rep)=>{
        this.keyVisionImage = null
        fd.set("file", this.backgroundImage)
        this.carouselSrv.uploadBackgroundImg(this.carousel,fd ,this.selectedLanguage).subscribe(rep=>{
          if(rep.status == 200){
            this.backgroundImage = null
            this.toastrSrv.success("新增 "+ this.carousel.title[this.selectedLanguage] +" 成功", "success")
          }
        })
      })
    } else {
      const fd = new FormData()
      if(this.keyVisionImage){
        fd.set("file", this.keyVisionImage)
        this.carouselSrv.uploadKeyVisionImg(this.carousel, fd, this.selectedLanguage).subscribe(rep=>{
          if(rep.status == 200){
            this.toastrSrv.success("修改 主視覺圖 成功", "success")
          }
        })
      }
      if (this.backgroundImage) {
        fd.set("file", this.backgroundImage)
        this.carouselSrv.uploadBackgroundImg(this.carousel, fd, this.selectedLanguage).subscribe(rep=>{
          if(rep.status == 200){
            this.toastrSrv.success("修改 背景圖片 成功", "success")
          }
        })
      }
      this.toastrSrv.success("修改 " + this.carousel.title[this.selectedLanguage] + " 成功", "success")
    }
  }

  checkNull(): boolean {
    if (this.isNew) {
      if (this.keyVisionImage && this.backgroundImage && this.name) {
        this.carousel.title.set(this.selectedLanguage, this.name)
        return true
      }
    } else {
      if (this.name) {
        this.carousel.title[this.selectedLanguage] = this.name;
        return true
      }
    }
    this.toastrSrv.info("必填欄位不可為空!", "提醒")
    return false
  }

  cancel() {
    history.go(-1)
    this.router.navigate(['/dashboard', 'carousel'])
  }

  fileUpload(event) {
    switch(event.target.nextElementSibling.id){
      case "keyVisionImage":
        this.keyVisionImage = <File> event.target.files[0]
        this.setImageUrl(this.keyVisionImage,"keyVisionImage")
        break;
      case "backgroundImage":
        this.backgroundImage = <File> event.target.files[0]
        this.setImageUrl(this.backgroundImage,"backgroundImage")
    }
  }

  setImageUrl(file: File, type: string) {
    if (file) {
      let reader = new FileReader();
      reader.onload = (e: any) => {
        if (type == "keyVisionImage") { this.keyVisionImageSrc = this.sanitizer.bypassSecurityTrustResourceUrl(e.target.result) }
        else if (type == "backgroundImage") { this.backgroundImageSrc = e.target.result }
      }
      reader.readAsDataURL(file)
    }
  }

  

  changeLanguage() {

  }

}
