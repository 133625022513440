import { SortDialogComponent } from './../../global/sort-dialog/sort-dialog.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatDialog, MatPaginatorIntl } from '@angular/material';
import { Article } from './article';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ArticleService } from './article.service';
import { DeleteDialogComponent } from 'src/app/global/delete-dialog/delete-dialog.component';

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.css']
})
export class ArticleComponent implements OnInit {

  keyword: string;
  tabIndex: number;
  status: string;
  articleDBSource = new MatTableDataSource<Article>();
  displayedColumns = ['name', 'action']
  statusLabel = ['啟用', '未啟用']
  totalCount: number
  page = 0;
  size = 10;

  @ViewChild('paginator') paginator: MatPaginator

  constructor(private articleSrv: ArticleService, private router: Router,
    private toastrSrv: ToastrService, private dialog: MatDialog, private matPaginatorIntl: MatPaginatorIntl) { }

  ngOnInit() {
    this.matPaginatorIntl.itemsPerPageLabel = '每頁筆數：';
    this.matPaginatorIntl.nextPageLabel = '下一頁';
    this.matPaginatorIntl.previousPageLabel = '上一頁';
    this.matPaginatorIntl.firstPageLabel = '第一頁'
    this.matPaginatorIntl.lastPageLabel = '最後一頁'
    this.tabIndex = 0
    this.buildData()
  }

  buildData() {
    this.status = this.tabIndex == 0 ? "ACTIVE" : "INACTIVE"
    if (this.keyword) {
      this.articleSrv.search(this.status, this.keyword, this.page, this.size).subscribe(rep => this.setData(rep))
    } else {
      this.articleSrv.getArticlesByStatus(this.status, this.page, this.size).subscribe(rep => this.setData(rep))
    }
  }

  setData(rep: any) {
    if(rep){
      this.articleDBSource.data = rep['content']
      this.totalCount = rep['totalElements']
    }
  }

  newArticle() {
    this.router.navigate(['/dashboard', 'article', 'newArticle'])
  }

  delete(article: Article) {
    let temp = []
    temp.push(article.id)
    const dialogRef = this.dialog.open(DeleteDialogComponent, { disableClose: true })
    dialogRef.afterClosed().subscribe(rep => {
      if (rep == "Yes") {
        this.articleSrv.delete(temp).subscribe(rep => {
          if (rep.status == 200) {
            this.toastrSrv.success('刪除 ' + article.title['zh_TW'] + ' 成功', "success")
            this.afterDelete()
          }
        })
      }
    })
  }

  afterDelete() {
    if ((this.totalCount % 10) === 1 && this.totalCount != 1) {
      this.page -= 1
      this.paginator.previousPage()
    } else {
      this.buildData()
    }
  }

  changeTab() {
    this.keyword = ""
    this.page = 0
    this.size = 10
    this.buildData()
  }

  search() {
    this.paginator.firstPage();
    this.buildData();
  }

  clear() {
    this.paginator.firstPage();
    this.keyword = ""
    this.buildData()
  }

  pageChanged(event) {
    this.page = event.pageIndex
    this.size = event.pageSize
    this.buildData()
  }

  changeSort() {
    const dialogRef = this.dialog.open(SortDialogComponent, { data: "article", disableClose: true, autoFocus: false })
    dialogRef.afterClosed().subscribe(rep => {
      if (rep == "save") this.buildData()
    })
  }

}
