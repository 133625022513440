import { Component, OnInit } from '@angular/core';
import { MatTableDataSource, MatDialog } from '@angular/material';
import { Keyword } from './keyword';
import { KeywordService } from './keyword.service';
import { KeywordDialogComponent } from './keyword-dialog.component';

@Component({
  selector: 'app-keyword',
  templateUrl: './keyword.component.html',
  styleUrls: ['./keyword.component.css']
})
export class KeywordComponent implements OnInit {

  searchText: string
  keywordDBSource = new MatTableDataSource<Keyword>()
  displayedColumns = ['keyword', 'content', 'description', 'action']

  constructor(private keywordSrv: KeywordService, private dialog: MatDialog) { }

  ngOnInit() {
    this.searchText = ""
    this.refresh()
  }

  refresh() {
    if (this.searchText) {
      this.keywordSrv.search(this.searchText).subscribe(rep => { this.keywordDBSource.data = rep.body['content'] })
    } else {
      this.keywordSrv.getAllKeywords().subscribe(rep => this.keywordDBSource.data = rep.body['content'])
    }
  }

  openDetail(keyword: Keyword) {
    const dialogRef = this.dialog.open(KeywordDialogComponent, { data: keyword })
    dialogRef.afterClosed().subscribe((rep) => { if (rep = "edit") { this.refresh() } })
  }

  clear() {
    this.searchText = ""
    this.refresh()
  }



}
