export class Article {

    id: string;
    status: "ACTIVE" | "INACTIVE";
    title: any
    outline: any
    content: any
    coverIds: Map<string, string>
    priority: number
    createTime: Date;
}
