import { Component, OnInit, ViewChild } from '@angular/core';
import { Carousel } from './carousel';
import { MatTableDataSource, MatDialog, MatPaginatorIntl, MatPaginator } from '@angular/material';
import { CarouselService } from './carousel.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DeleteDialogComponent } from '../global/delete-dialog/delete-dialog.component';
import { SortDialogComponent } from '../global/sort-dialog/sort-dialog.component';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css']
})
export class CarouselComponent implements OnInit {

  tabIndex: number
  keyword: string
  status: string
  carousel: Carousel
  carouselDataSource = new MatTableDataSource<Carousel>();
  displayedColumns = ['name', 'action']
  totalCount: number
  size: number = 10;
  page: number = 0;

  @ViewChild('paginator') paginator: MatPaginator

  constructor(private carouselSrv: CarouselService, private router: Router,
    private toastrSrv: ToastrService, private oepnDialog: MatDialog,
    private matPaginatorIntl: MatPaginatorIntl) { }

  ngOnInit() {
    this.matPaginatorIntl.itemsPerPageLabel = '每頁筆數：';
    this.matPaginatorIntl.nextPageLabel = '下一頁';
    this.matPaginatorIntl.previousPageLabel = '上一頁';
    this.matPaginatorIntl.firstPageLabel = '第一頁'
    this.matPaginatorIntl.lastPageLabel = '最後一頁'
    this.tabIndex = 0
    this.refresh()
  }

  refresh() {
    this.status = this.tabIndex == 0 ? "ACTIVE" : "INACTIVE"
    if (this.keyword) {
      this.carouselSrv.search(this.status, this.keyword, this.page, this.size).subscribe(rep => this.setData(rep))
    } else {
      this.carouselSrv.getCarouselByStatus(this.status, this.page, this.size).subscribe(rep => this.setData(rep))
    }
  }

  setData(rep) {
    this.carouselDataSource.data = rep.body['content'];
    this.totalCount = rep.body['totalElements']
  }

  newCarousel() {
    this.router.navigate(['/dashboard', 'carousel', 'null'])
  }

  delete(carousel: Carousel) {
    let temp = []
    temp.push(carousel.id)
    const dialogRef = this.oepnDialog.open(DeleteDialogComponent)
    dialogRef.afterClosed().subscribe(rep => {
      if (rep == "Yes") {
        this.carouselSrv.delete(temp).subscribe(rep => {
          if (rep.status == 200) {
            this.toastrSrv.success('刪除 ' + carousel.title['zh_TW'] + ' 成功', "success")
            this.afterDelete()
          }
        })
      }
    })
  }

  afterDelete() {
    if ((this.totalCount % 10) === 1 && this.totalCount!=1) {
      this.page -= 1
      this.paginator.previousPage()
    } else {
      this.refresh()
    }
  }

  changeTab() {
    this.keyword = ""
    this.page = 0
    this.size = 10
    this.refresh()
  }

  search() {
    this.paginator.firstPage();
    this.refresh();
  }

  clear() {
    this.paginator.firstPage();
    this.keyword = ""
    this.refresh()
  }

  pageChanged(event) {
    this.page = event.pageIndex
    this.size = event.pageSize
    this.refresh()
  }

  changeSort() {
    this.oepnDialog.open(SortDialogComponent, { data: "carousel" })
  }

}
