import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-type-dialog',
  templateUrl: './type-dialog.component.html',
  styleUrls: ['./type-dialog.component.css']
})
export class TypeDialogComponent implements OnInit {

  selectedType : string

  constructor(private dialogRef : MatDialogRef<TypeDialogComponent>) { }

  ngOnInit() {
    this.selectedType = "products"
  }

  chooseType(){
    this.dialogRef.close(this.selectedType)
  }


}
