import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatDialog, MatPaginator } from '@angular/material';
import { Product } from './product';
import { ProductService } from '../products/product.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { DeleteDialogComponent } from '../../global/delete-dialog/delete-dialog.component';
import { SortDialogComponent } from '../../global/sort-dialog/sort-dialog.component';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {

  status: string
  product = new Product();
  totalCount: number
  productDBSource = new MatTableDataSource<Product>();
  displayedColumns = ['name', 'action']
  keyword: string
  tabIndex: number
  page = 0
  size = 10

  @ViewChild('paginator') paginator : MatPaginator

  constructor(private productSrv: ProductService, private router: Router,
     private toastrSrv: ToastrService, private dialog: MatDialog) { }

  ngOnInit() {
    this.tabIndex = 0
    this.refresh()
  }

  refresh() {
    if (this.tabIndex == 0) {
      this.status = "ACTIVE"
      if(this.keyword){
        this.productSrv.search(this.status,this.keyword,this.page, this.size).subscribe(rep => {
          this.productDBSource.data = rep.body['content']
          this.totalCount = rep.body['totalElements']
        })
      } else {
        this.productSrv.getProductByStatus(this.status, this.page, this.size).subscribe(rep => {

          this.productDBSource.data = rep.body['content']
          this.totalCount = rep.body['totalElements']
        })
      }
    } else if (this.tabIndex == 1) {
      this.status = "INACTIVE"
      if(this.keyword){
        this.productSrv.search(this.status,this.keyword,this.page, this.size).subscribe(rep => {
          this.productDBSource.data = rep.body['content']
          this.totalCount = rep.body['totalElements']
        })
      }else {
        this.productSrv.getProductByStatus(this.status, this.page, this.size).subscribe(rep => {
          this.productDBSource.data = rep.body['content']
          this.totalCount = rep.body['totalElements']
        })
      }
    }
  }

  newProduct() {
    this.router.navigate(['/dashboard','products','null','product'])
  }

  delete(product:Product) {
    let tempIds = []
    tempIds.push(product.id)
    const dialogRef = this.dialog.open(DeleteDialogComponent)
    dialogRef.afterClosed().subscribe(rep=>{
      if(rep == "Yes"){
        this.productSrv.delete(tempIds).subscribe(rep=>{
          if(rep.status == 200){
            this.toastrSrv.success("刪除"+ product.title['zh_TW'] + '成功','Success')
            this.afterDelete()
          }
        })
      }
    })
  }

  afterDelete(){
    if((this.totalCount%10) ==1 && this.totalCount!=1){
      this.page-=1
      this.paginator.previousPage()
    }else{
      this.refresh()
    } 
  }

  changeTab(){
    this.keyword = ""
    this.page = 0
    this.size = 10
    this.refresh()
  }

  changeSort() {
    this.dialog.open(SortDialogComponent, { data: "product" })
  }

  pageChanged(event) {
    this.page = event.pageIndex
    this.size = event.pageSize
    this.refresh()
  }

  search(){
    this.paginator.firstPage();
    this.refresh()
  }

  clear(){
    this.paginator.firstPage()
    this.keyword = ""
    this.refresh()
  }
}
