import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Carousel } from './carousel';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CarouselService {

  constructor(private http: HttpClient) { }

  public getCarouselByStatus(status: string, page, size) {
    return this.http.get<Carousel[]>(environment.serverUrl + "/public/carousel/status/" + status + "?page=" + page + "&size=" + size + "&sort=priority,asc", { observe: "response" })
  }

  public getCarouselById(id: string) {
    return this.http.get<Carousel>(environment.serverUrl + "/public/carousel/" + id, { observe: "response" })
  }

  public search(status: string, keyword: string, page: number, size: number) {
    return this.http.get<Carousel[]>(environment.serverUrl + "/public/carousel/status/" + status + "/language/zh_TW/titleValue/" + keyword + "?page=" + page + "&size=" + size, { observe: "response" })
  }

  public create(carousel:Carousel, language: string) {
    switch (language) {
      case "zh_TW":
        return this.http.post<Carousel>(environment.serverUrl + "/super/carousel",
          {
            "title": { "zh_TW": carousel.title.get(language) },
            "status": carousel.status,      
          },
          { observe: "response" })
      case "en_US":
        return this.http.post<Carousel>(environment.serverUrl + "/super/carousel",
          {
            "title": { "en_US": carousel.title.get(language) },
            "status": carousel.status,
          },
          { observe: "response" })
      default:
        throw Error("語系錯誤，請確認語系為中文或英文")
    }
  }

  public update(carousel:Carousel) {
    return this.http.put(environment.serverUrl + "/super/carousel", carousel, { observe: "response" })
  }

  public delete(ids: string[]) {
    return this.http.request("delete", environment.serverUrl + "/super/carousel", { body: ids, observe: "response" })
  }

  public changeSort(carousel: Carousel[]) {
    return this.http.put(environment.serverUrl + "/super/carousel/priority", carousel, { observe: "response" })
  }

  public uploadKeyVisionImg(carousel:Carousel, img: FormData, language: string) {
    return this.http.post(environment.serverUrl + "/super/carousel/" + carousel.id + "/language/" + language + "/upload/keyVisionPicture", img, { observe: "response" })
  }

  public uploadBackgroundImg(carousel:Carousel, img: FormData, language: string) {
    return this.http.post(environment.serverUrl + "/super/carousel/" + carousel.id + "/language/" + language + "/upload/background", img, { observe: "response" })
  }

}
