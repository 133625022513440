import { Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Category } from './category';
import { Attribute } from '../attributes/attribute';
import { Product } from '../products/product';
import { FormControl } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { MatSelect } from '@angular/material';
import { CategoryService } from './category.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AttributeService } from '../attributes/attribute.service';
import { ProductService } from '../products/product.service';
import { takeUntil } from 'rxjs/operators';
import { DropEffect, DndDropEvent } from 'ngx-drag-drop';
import { environment } from '../../../environments/environment.prod';

@Component({
  selector: 'app-category-edit',
  templateUrl: './category-edit.component.html',
  styleUrls: ['../products/products.component.css']
})
export class CategoryEditComponent implements OnInit {

  id: string
  type: string
  name: string
  isNew = false
  _category = new Category()

  iconImage: File = null
  iconImageSrc: SafeResourceUrl = ''
  target = environment.serverUrl+"/public/file/"

  selectedLanguage: string
  selectedAttributeId: string[] = []
  selectedProductId: string[] = []
  attributeList: Attribute[] = []
  productList: Product[] = []

  attributeMultiCtrl: FormControl = new FormControl();
  attributeFilterCtrl: FormControl = new FormControl()
  filteredAttributeMulti: ReplaySubject<Attribute[]> = new ReplaySubject()
  @ViewChild('attribute') attributeSelect: MatSelect

  productMultiCtrl: FormControl = new FormControl();
  productFilterCtrl: FormControl = new FormControl()
  filteredProductMulti: ReplaySubject<Product[]> = new ReplaySubject()
  @ViewChild('product') productSelect: MatSelect
  private _onDestroy = new Subject<void>()


  constructor(private categorySrv: CategoryService, private router: Router,
    private route: ActivatedRoute, private toastrSrv: ToastrService, private sanitizer: DomSanitizer,
    private attributeSrv: AttributeService, private productSrv: ProductService) { }

  ngOnInit() {
    this.selectedLanguage = "zh_TW"
    this.route.params.subscribe(param => { this.id = param.id; this.type = param.type })
    if (this.id === "null") {
      this.isNew = true
      this._category.status = "ACTIVE"
      this._category.title = new Map();
      this.iconImageSrc = ""
      this.getAttribute()
    } else {
      this.categorySrv.getCategoryById(this.id).subscribe(rep => this._category = rep.body)
      this.getAttribute()
    }
  }

  getAttribute() {
    this.attributeSrv.getAllAttribute().subscribe(rep => {
      this.attributeList = rep['content']
      this.attributeList.forEach(item => {
        if (item.status == "INACTIVE") { item.title[this.selectedLanguage] = "(停用) " + item.title[this.selectedLanguage] }
        item['disable'] = false
        item['effectAllowed'] = "copyMove"
      })
      this.filteredAttributeMulti.next(this.attributeList.slice())
      this.attributeFilterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => { this.filterAttributesMulti(); })
      this.getProducts()
    })
  }

  getProducts() {
    this.productSrv.getAllProduct().subscribe(rep => {
      this.productList = rep['content']
      this.productList.forEach(item => {
        if (item.status == "INACTIVE") { item.title[this.selectedLanguage] = "(停用) " + item.title[this.selectedLanguage] }
        item['disable'] = false
        item['effectAllowed'] = "copyMove"
      })
      this.filteredProductMulti.next(this.productList.slice())
      this.productFilterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => { this.filterProductsMulti(); })
      this.setValue()
    })
  }

  setValue() {
    if (!this.isNew) {
      let tempAttribute = []
      let tempProduct = []
      this.name = this._category.title[this.selectedLanguage]
      this._category.technicalAttributeIds.forEach(id => { tempAttribute.push(this.attributeList.filter(item => item.id == id)[0]) })
      this._category.productIds.forEach(id => tempProduct.push(this.productList.filter(item => item.id == id)[0]))
      this.attributeSelect.value = tempAttribute
      this.attributeMultiCtrl.setValue(this.attributeSelect.value)
      this.productSelect.value = tempProduct
      this.productMultiCtrl.setValue(this.productSelect.value)
      this.setAttributeId()
      this.setProductId()
      if (this._category.iconIds) { this.iconImageSrc = this.target + this._category.iconIds[this.selectedLanguage] }
    }
  }

  save() {
    if (this.isNew) {
      if (this.checkNull()) {
        this.categorySrv.create(this._category, this.selectedLanguage).subscribe(rep => {
          if (rep.status == 200) {
            this._category = rep.body
            this.imageUploadToDatabase(rep.body["id"])
            this.isNew = false
          }
        })
      }
    } else {
      if (this.checkNull()) {
        this.categorySrv.update(this._category).subscribe(rep => {
          if (rep.status == 200) {
            this.imageUploadToDatabase(this._category.id)
            this.toastrSrv.success("修改 " + this._category.title[this.selectedLanguage] + " 成功", "success")
          }
        })
      }
    }
  }

  imageUploadToDatabase(id: string) {
    if (this.isNew) {
      const fd = new FormData()
      fd.set("file", this.iconImage)
      this.categorySrv.uploadIconImg(this._category, fd, this.selectedLanguage).subscribe(() => {
        this.iconImage = null
        this.toastrSrv.success("新增 " + this._category.title[this.selectedLanguage] + " 成功", "success")
      })
    } else {
      if (this.iconImage) {
        const fd = new FormData()
        fd.set("file", this.iconImage)
        this.categorySrv.uploadIconImg(this._category, fd, this.selectedLanguage).subscribe(() => { this.iconImage = null })
      }
    }
  }


  checkNull(): boolean {
    if (this.isNew) {
      if (this.name && this.iconImage) {
        if (this.selectedAttributeId) this._category.technicalAttributeIds = this.selectedAttributeId
        if (this.selectedProductId) this._category.productIds = this.selectedProductId
        this._category.title.set(this.selectedLanguage, this.name)
        return true
      }
      this.toastrSrv.info("必填欄位不可為空!", "提醒")
      return false
    } else {
      if (this.name) {
        if (this.selectedAttributeId) this._category.technicalAttributeIds = this.selectedAttributeId
        if (this.selectedProductId) this._category.productIds = this.selectedProductId
        this._category.title[this.selectedLanguage] = this.name
        return true
      }
      this.toastrSrv.info("必填欄位不可為空!", "提醒")
      return false
    }
  }

  cancel() {
    history.go(-1)
    if (this.type == 'product') {
      this.router.navigate(['/dashboard', 'products'])
    } else if (this.type == 'category') {
      this.router.navigate(['/dashboard', 'categories'])
    }
  }

  onDragged(item: any, list: any[], effect: DropEffect) {
    if (effect === "move") {
      const index = list.indexOf(item);
      list.splice(index, 1);
    }
  }

  onDrop(event: DndDropEvent, list?: any[]) {
    if (list && (event.dropEffect === "copy" || event.dropEffect === "move")) {
      let index = event.index;
      if (typeof index === "undefined") {
        index = list.length;
      }
      list.splice(index, 0, event.data);
    }
  }

  removeAttributeTag(attribute: Attribute) {
    this.attributeSelect.value = this.attributeSelect.value.filter(tag => tag !== attribute);
    this.setAttributeId()
  }

  removeProductTag(attribute: Attribute) {
    this.productSelect.value = this.productSelect.value.filter(tag => tag !== attribute);
    this.setProductId()
  }

  iconUpload(event) {
    this.iconImage = <File>event.target.files[0]
    if (this.iconImage) {
      let reader = new FileReader();
      reader.onload = (e: any) => {
        this.iconImageSrc = this.sanitizer.bypassSecurityTrustResourceUrl(e.target.result)
      }
      reader.readAsDataURL(this.iconImage)
    }
  }

  filterAttributesMulti() {
    if (!this.attributeList) {
      return;
    }
    let search = this.attributeFilterCtrl.value
    if (!search) {
      this.filteredAttributeMulti.next(this.attributeList.slice())
      return
    } else {
      search = search.toLowerCase();
    }
    this.filteredAttributeMulti.next(
      this.attributeList.filter(attribute => attribute.title[this.selectedLanguage].toLowerCase().indexOf(search) > -1))
  }

  filterProductsMulti() {
    if (!this.productList) {
      return;
    }
    let search = this.productFilterCtrl.value
    if (!search) {
      this.filteredProductMulti.next(this.productList.slice())
      return
    } else {
      search = search.toLowerCase();
    }
    this.filteredProductMulti.next(
      this.productList.filter(product => product.title[this.selectedLanguage].toLowerCase().indexOf(search) > -1))
  }


  setAttributeId() {
    let temp = []
    this.attributeSelect.value.forEach(item => temp.push(item.id))
    this.selectedAttributeId = temp
  }

  setProductId() {
    let temp = []
    this.productSelect.value.forEach(item => temp.push(item.id))
    this.selectedProductId = temp
  }
}
