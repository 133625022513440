import { Url } from "../product/products/product";

export class Carousel {
    id : string
    status : string
    title: Map<string, string>
    iconIds: Map<string,string>
    backgroundIds: Map<string,string>
    keyVisionPictureIds: Map<string, string>
    urls: Map<string,Array<Url>>
    priority: number
}
