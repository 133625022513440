import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { Authorization } from './authorization';
import { environment } from '../../environments/environment';
import { GlobalService } from '../global/global.service';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {

  constructor(private http: HttpClient, private globalSrv: GlobalService, private router: Router) { }

  fetchAccessToken(userId, password): Observable<Authorization> {
    return this.http.post<Authorization>(
      environment.serverUrl + '/public/login',
      { userId, password })
  }

  fetchRenewToken(renewToken) {
    return this.http.post<Authorization>(
      environment.serverUrl + '/public/refreshToken',renewToken)
  }

  isInternalUserAndNotExpired() {
    if (this.globalSrv.authInfo == undefined) {
      return false;
    } else if (this.globalSrv.authInfo.appRole === 'PLATFORM_ADMIN') {
      return true;
    }
    return false;
  }

  logout() {
    this.globalSrv.authInfo = null;
    this.router.navigate(['/login'])
  }

  getHttpHeaders(): HttpHeaders {
    let now: number = new Date().getTime();
    let token: string = ''

    if (this.globalSrv.authInfo !== null) {
      token = this.globalSrv.authInfo.token;
    }

    return new HttpHeaders({
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token
    })
  }
}
