export class ErrorLog{
    id : string
    level : string
	loggerName : string
	message : string
	source : string
	marker : string
	threadId : number
	threadName : string
    threadPriority : number
	millis : number
	date : Date
	thrown : string
	contentMap : Map<string,object>
	contextStack : Array<string>
}