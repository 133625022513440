import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ActionLog } from '../action-log';

@Component({
  selector: 'app-actionlog-dialog',
  templateUrl: './actionlog-dialog.component.html',
  styleUrls: ['../action-log.component.css']
})
export class ActionlogDialogComponent implements OnInit {

  actionLog : ActionLog

  constructor( @Inject(MAT_DIALOG_DATA) private data : any) { }

  ngOnInit() {
    this.actionLog = this.data
  }

}
