import { Injectable } from "@angular/core";
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpHeaders,
    HttpErrorResponse,
    HttpSentEvent,
    HttpHeaderResponse,
    HttpProgressEvent,
    HttpResponse,
    HttpUserEvent
} from "@angular/common/http";
import { Observable, throwError, of, BehaviorSubject } from "rxjs";
import { catchError, switchMap, finalize, filter, take } from 'rxjs/operators';
import { AuthorizationService } from "./authorization.service";
import { GlobalService } from "../global/global.service";
import { Authorization } from "./authorization";


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    private httpHeaders: HttpHeaders;
    private isRefreshingToken: boolean = false;
    private tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    constructor(private authorizationService: AuthorizationService, private globalSrv: GlobalService, ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any> | any> {
        if (req.url.indexOf('/login') <= 0) {
            return next.handle(this.addHeaderToRequest(req))
                .pipe(
                    catchError(error => {
                        if (error instanceof HttpErrorResponse) {
                            switch ((<HttpErrorResponse>error).status) {
                                case 401:
                                    return this.handleAuthError(req, next);
                                default:
                                    return throwError(error);
                            }
                        } else {
                            return throwError(error);
                        }
                    }));
        } else
            return next.handle(req)
    }

    private addHeaderToRequest(req: HttpRequest<any>): HttpRequest<any> {
        if(req.url.indexOf('/upload') <= 0){
            this.httpHeaders = this.authorizationService.getHttpHeaders();
        }else {
            this.httpHeaders = new HttpHeaders({
                "Authorization": "Bearer " + this.globalSrv.authInfo.token
            })
        }
        return req.clone({ headers: this.httpHeaders })
    }

    private handleAuthError(req: HttpRequest<any>, next: HttpHandler) {

        if (!this.isRefreshingToken) {
            this.isRefreshingToken = true;


            this.tokenSubject.next(null);

            return this.authorizationService.fetchRenewToken(this.globalSrv.authInfo.renewToken)
                .pipe(
                    switchMap((user: Authorization) => {
                        if (user) {
                            this.tokenSubject.next(user.token);
                            this.globalSrv.authInfo = user
                            return next.handle(this.addHeaderToRequest(req))
                        }

                        return <any>this.authorizationService.logout();
                    }),
                    catchError(err => {
                        return <any>this.authorizationService.logout();
                    }),
                    finalize(() => {
                        this.isRefreshingToken = false
                    })
                )
        } else {
            this.isRefreshingToken = false;
            return this.tokenSubject
                .pipe(filter(token => token != null),
                    take(1),
                    switchMap(() => {
                        return next.handle(this.addHeaderToRequest(req))
                    }));
        }

    }
}


