import { ArticleCategory } from './article-category';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ArticleCategoryService {

  constructor(private http: HttpClient) { }

  public getAllArticleCategories() {
    return this.http.get<ArticleCategory[]>(environment.serverUrl + "/public/acticleCategory", { observe: "body" })
  }

  public getArticleCategoryById(id: string) {
    return this.http.get<ArticleCategory>(environment.serverUrl + "/public/acticleCategory/" + id, { observe: "body" })
  }

  public save(articleCategory: ArticleCategory, isNew: boolean) {
    return isNew ? this.create(articleCategory) : this.update(articleCategory)
  }

  private create(articleCategory: ArticleCategory) {
    return this.http.post<ArticleCategory>(environment.serverUrl + "/super/acticleCategory", articleCategory, { observe: "response" })
  }

  private update(articleCategory: ArticleCategory) {
    return this.http.put<ArticleCategory>(environment.serverUrl + "/super/acticleCategory", articleCategory, { observe: "response" })
  }

  public delete(ids: string[]) {
    return this.http.request("delete", environment.serverUrl + "/super/acticleCategory", { body: ids, observe: "response" })
  }

  public search(keyword: string) {
    return this.http.get<ArticleCategory[]>(environment.serverUrl + "/public/acticleCategory/language/zh_TW/titleValue/" + keyword, { observe: "body" })
  }
}
