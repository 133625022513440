import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Account } from './account';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(private http: HttpClient) { }

  public getAllAccountByStatus(status: string, page: number, size: number) {
    return this.http.get<any>(environment.serverUrl + "/super/account/status/" + status + "?page=" + page + "&size=" + size, { observe: 'response' });
  }

  public search(status, keyword, page: number, size: number) {
    return this.http.get<any>(
      environment.serverUrl + "/super/account/status/" + status + "/search/" + keyword + "?page=" + page + "&size=" + size , { observe: "response" })
  }

  public create(account: Account) {
    return this.http.post(environment.serverUrl + "/super/account", account, { observe: 'response' });
  }

  public getAccountById(userId: string) {
    return this.http.get<any>(environment.serverUrl + "/super/account/" + userId, { observe: 'response' });
  }

  public update(account: Account) {
    return this.http.put(environment.serverUrl + "/super/account", account, { observe: 'response' });
  }

  public delete(userIds: string[]) {
    return this.http.request("delete", environment.serverUrl + "/super/account/delete", { body: userIds, observe: "response" });
  }

  public changePassword(userId: string, oldPassword: string, newPassword: string) {
    return this.http.post(
      environment.serverUrl + "/api/changePassword",
      {
        "userId": userId,
        "oldPassword": oldPassword,
        "password": newPassword
      }, { observe: "response" })
  }
}
