import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KeywordComponent } from './keyword.component';
import { KeywordDialogComponent } from './keyword-dialog.component';
import { AppMaterialModule } from '../app-material/app-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';


@NgModule({
  imports: [
    CommonModule,
    AppMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule
  ],
  entryComponents:[KeywordDialogComponent],
  declarations: [KeywordComponent,KeywordDialogComponent]
})
export class KeywordModule { }
