import { Article } from './article';
import { environment } from './../../../environments/environment.prod';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ArticleService {

  constructor(private http: HttpClient) { }

  public getArticlesByStatus(status: string, page: number, size: number) {
    return this.http.get<Article[]>(environment.serverUrl + "/public/article/status/" + status + "?page=" + page + "&size=" + size + "&sort=priority,asc", { observe: "body" })
  }

  public getAllArticles() {
    return this.http.get<Article[]>(environment.serverUrl + "/public/article", { observe: "body" })
  }

  public getArticleById(id: string) {
    return this.http.get<Article>(environment.serverUrl + "/public/article/" + id, { observe: "body" })
  }

  public changeSort(articles: Article[]) {
    return this.http.put(environment.serverUrl + "/super/article/priority", articles, { observe: "response" })
  }

  public save(article: Article, isNew: boolean) {
    return isNew ? this.create(article) : this.update(article)
  }

  private create(article: Article) {
    return this.http.post<Article>(environment.serverUrl + "/super/article", article, { observe: "response" })
  }

  private update(article: Article) {
    return this.http.put<Article>(environment.serverUrl + "/super/article", article, { observe: "response" })
  }

  public delete(ids: string[]) {
    return this.http.request("delete", environment.serverUrl + "/super/article", { body: ids, observe: "response" })
  }

  public search(status: string, keyword: string, page: number, size: number) {
    return this.http.get<Article[]>(environment.serverUrl + "/public/article/status/" + status + "/language/zh_TW/titleValue/" + keyword + "?page=" + page + "&size=" + size, { observe: "response" })
  }

  public uploadCoverImg(article: Article, img: FormData, language: string) {
    return this.http.post(environment.serverUrl + "/super/article/" + article.id + "/language/" + language + "/upload/coverPicture", img, { observe: "response" })
  }

}
