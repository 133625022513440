import { ToastrService } from 'ngx-toastr';
import { ArticleService } from './article.service';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Article } from './article';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-article-edit',
  templateUrl: './article-edit.component.html',
  styleUrls: ['./article.component.css']
})
export class ArticleEditComponent implements OnInit {

  isNew = false
  isFinish: boolean;
  imageLoaded: boolean;
  article = new Article();
  selectedLanguage: string;
  lang = ["zh_TW", "en_US"]

  target = environment.serverUrl + "/public/file/"
  image: File = null;
  imageSrc = "";

  editorConfig = {
    editable: true,
    spellcheck: false,
    height: '25rem',
    minHeight: '10rem',
    placeholder: '請輸入內容',
    translate: 'no',
    imageEndPoint: environment.serverUrl + '/public/file/upload'
  };


  constructor(private articleSrv: ArticleService, private router: Router,
    private route: ActivatedRoute, private toastrSrv: ToastrService) { }

  ngOnInit() {
    this.imageLoaded = true;
    this.selectedLanguage = "zh_TW";
    this.route.params.subscribe(param => this.initData(param.id))
  }

  private initData(id: string) {
    this.article.title = {}
    this.article.outline = {}
    this.article.content = {}
    if (id === "newArticle") {
      this.isNew = true;
      this.article.status = "INACTIVE"
      this.article.outline[this.selectedLanguage] = ''
    } else {
      this.articleSrv.getArticleById(id).subscribe(rep => {
        this.article = rep
        this.imageSrc = this.article.coverIds[this.selectedLanguage] ? this.target + this.article.coverIds[this.selectedLanguage] : ""
      })
    }
  }

  save() {
    if (this.checkNull()) {
      this.toastrSrv.info("必填欄位不可為空", "Info")
      return;
    }

    if (this.checkImageExist()) {
      this.toastrSrv.info("請上傳一張圖片", "Info")
      return;
    }

    this.articleSrv.save(this.article, this.isNew).subscribe(rep => {
      if (rep.status == 200) {
        this.uploadImg(rep)
        if (this.isNew) {
          this.isNew = false;
          this.toastrSrv.success("新增 " + this.article.title[this.selectedLanguage] + " 成功")
        } else {
          this.toastrSrv.success("修改 " + this.article.title[this.selectedLanguage] + " 成功")
        }
        this.article = rep.body
      }
    })

  }

  uploadImg(rep: any) {
    if (rep.status == 200 && this.image) {
      this.article = rep.body;
      const fd = new FormData();
      fd.set("file", this.image);
      this.articleSrv.uploadCoverImg(this.article, fd, this.selectedLanguage).subscribe(rep => {
        if (rep.status != 200) {
          this.article.status = "INACTIVE"
          this.toastrSrv.error("上傳圖片失敗，請重新上傳", "Error")
        } else {
          this.toastrSrv.success("修改圖片成功", "Success")
        }
      })
    }
  }

  checkNull():boolean {
    if (this.article.status == "ACTIVE") {
      if (!(this.article.title[this.selectedLanguage] && this.article.outline[this.selectedLanguage] && this.article.content[this.selectedLanguage])) return true;
    } else {
      if (!(this.article.title[this.selectedLanguage] && this.article.outline[this.selectedLanguage])) return true;
    }
    return false;
  }

  checkImageExist():boolean{
    if(this.article.status == "ACTIVE"){
      return this.isNew ? this.image == null : (this.article.coverIds[this.selectedLanguage] == null || this.article.coverIds[this.selectedLanguage] == undefined) && this.image == null
    }
    return false;
  }

  cancel() {
    this.router.navigate(["/dashboard", "article"])
  }

  fileUpload(event) {
    this.image = <File>event.target.files[0]
    if (this.image) {
      let reader = new FileReader();
      reader.onload = (e: any) => {
        this.imageSrc = e.target.result
      }
      reader.readAsDataURL(this.image)
    }
  }

  loadImage() {
    this.imageLoaded = true;
  }

}
