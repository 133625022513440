import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../global/global.service';
import { AccountService } from '../account/account.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  user:string=""
  role:string=""
  constructor(private globalSrv : GlobalService,private accSrv : AccountService,private router: Router) { }

  ngOnInit() {
    this.accSrv.getAccountById(this.globalSrv.authInfo.userId).subscribe(rep => {
      this.user = rep.body['fullName']
      this.role = rep.body['role']
    })
  }

  logout() {
    this.globalSrv.authInfo = null;
    this.router.navigate(['/login'])
  }

}
