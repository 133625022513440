import { ArticleModule } from './article/article.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, ErrorHandler } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ActionLogComponent } from './action-log/action-log.component';
import { ErrorLogComponent } from './error-log/error-log.component';

import { AccountModule } from './account/account.module';
import { AppMaterialModule } from './app-material/app-material.module';
import { ToastrModule } from 'ngx-toastr';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { GlobalService } from './global/global.service';
import { AuthInterceptor } from './authorization/auth.interceptor';
import { GlobalErrorHandler } from './errorHandler/globalErrorHandler';
import { ActionlogDialogComponent } from './action-log/actionlog-dialog/actionlog-dialog.component';
import { ErrorlogDialogComponent } from './error-log/errorlog-dialog/errorlog-dialog.component';
import { ProductModule } from './product/product.module';
import { DndModule } from 'ngx-drag-drop';
import { DeleteDialogComponent } from './global/delete-dialog/delete-dialog.component';
import { SortDialogComponent } from './global/sort-dialog/sort-dialog.component';
import { CarouselModule } from './carousel/carousel.module';
import { StoryModule } from './story/story.module';
import { KeywordModule } from './keyword/keyword.module';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    ChangePasswordComponent,
    ActionLogComponent,
    ErrorLogComponent,
    ActionlogDialogComponent,
    ErrorlogDialogComponent,
    DeleteDialogComponent,
    SortDialogComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppMaterialModule,
    AccountModule,
    ProductModule,
    KeywordModule,
    CarouselModule,
    StoryModule,
    ArticleModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDaterangepickerMd,
    DndModule,
    ToastrModule.forRoot(),
  ],
  providers: [GlobalService,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }],
  entryComponents: [ActionlogDialogComponent,ErrorlogDialogComponent,DeleteDialogComponent,SortDialogComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
