import { ToastrService } from 'ngx-toastr';
import { ArticleCategoryService } from './../article-category.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatSelect } from '@angular/material';
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { ArticleCategory } from '../article-category';
import { ArticleService } from '../../articles/article.service';
import { Article } from '../../articles/article';
import { FormControl } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-article-category-edit-dialog',
  templateUrl: './article-category-edit-dialog.component.html',
  styleUrls: ['./article-category-edit-dialog.component.css']
})
export class ArticleCategoryEditDialogComponent implements OnInit {

  isNew: boolean = false;;

  articleCategory: ArticleCategory
  selectedLanguage: "zh_TW" | "en_US"

  articles: Article[] = []
  selectedArticles: Article[] = []

  articleMultiCtrl: FormControl = new FormControl();
  articleFilterCtrl: FormControl = new FormControl();
  filteredArticleMulti: ReplaySubject<Article[]> = new ReplaySubject();
  @ViewChild('article') articleSelect: MatSelect
  private _onDestroy = new Subject<void>()

  constructor(@Inject(MAT_DIALOG_DATA) private data: string, private articleCategorySrv: ArticleCategoryService,
    private dialogRef: MatDialogRef<ArticleCategoryEditDialogComponent>, private articleSrv: ArticleService, private toastrSrv: ToastrService) { }

  ngOnInit() {
    this.selectedLanguage = "zh_TW";
    if (this.data == "newArticleCategory") {
      this.articleCategory = new ArticleCategory();
      this.articleCategory.title = new Map();
      this.isNew = true;
      this.initArticleItem()
    } else {
      this.articleCategorySrv.getArticleCategoryById(this.data).toPromise()
        .then(rep => this.articleCategory = rep)
        .then(() => this.initArticleItem())
    }


  }

  initArticleItem() {
    if(!this.articleCategory.color) this.articleCategory.color = "#ffffff"
    this.articleSrv.getAllArticles().subscribe(rep => {
      this.articles = rep.filter(article => article.status == "ACTIVE")
      this.filteredArticleMulti.next(this.articles.slice())
      this.articleFilterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => this.filterArticlesMulti())
      this.setSelectValue()
    })
  }

  setSelectValue() {
    if (this.isNew) return;
    let selectedValue = []
    this.articleCategory.articleIds.forEach(id => selectedValue.push(this.articles.find(item => item.id == id)))
    this.articleSelect.value = selectedValue;
    this.articleMultiCtrl.setValue(this.articleSelect.value)
    this.setArticleId()
  }

  save() {
    if (this.checkNull()) {
      this.toastrSrv.info("必填欄位不可為空！", "Info")
      return;
    }
    this.articleCategorySrv.save(this.articleCategory, this.isNew).subscribe(rep => {
      if (rep.status == 200) {
        if (this.isNew) {
          this.isNew = false;
          this.toastrSrv.success("新增 " + this.articleCategory.title[this.selectedLanguage] + " 成功")
        } else {
          this.toastrSrv.success("修改 " + this.articleCategory.title[this.selectedLanguage] + " 成功")
        }
        this.articleCategory = rep.body
        this.dialogRef.close("update")
      }
    })
  }

  checkNull(): boolean {
    console.log(this.articleCategory)
    if (!this.articleCategory.title[this.selectedLanguage]) return true;
    console.log(this.selectedArticles)
    if (this.selectedArticles.length == 0) {
      return true
    } else {
      this.articleCategory.articleIds = []
      this.selectedArticles.sort(function (a, b) {
        return a.priority - b.priority;
      })
      this.selectedArticles.forEach(article => this.articleCategory.articleIds.push(article.id))
    }
    return false;
  }

  closeDialog(action: string) {
    this.dialogRef.close(action)
  }

  setArticleId() {
    let temp = []
    this.selectedArticles = this.articleSelect.value
  }

  removeAttributeTag(article: Article) {
    this.articleSelect.value = this.articleSelect.value.filter(tag => tag !== article);
    this.setArticleId()
  }

  filterArticlesMulti() {
    if (!this.articles) return;

    let search = this.articleFilterCtrl.value
    if (!search) {
      this.filteredArticleMulti.next(this.articles.slice())
      return;
    } else {
      search = search.toLowerCase()
    }
    this.filteredArticleMulti.next(
      this.articles.filter(article => article.title[this.selectedLanguage].toLowerCase().indexOf(search) > -1))
  }

}
