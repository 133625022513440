import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArticleComponent } from './articles/article.component';
import { ArticleCategoryComponent } from './article-category/article-category.component';
import { ArticleEditComponent } from './articles/article-edit.component';
import { ArticleCategoryEditDialogComponent } from './article-category/article-category-edit-dialog/article-category-edit-dialog.component';
import { RouterModule } from '@angular/router';
import { AppMaterialModule } from '../app-material/app-material.module';
import { NgxEditorModule } from 'ngx-editor';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search'
import { ColorPickerModule } from 'ngx-color-picker';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    AppMaterialModule,
    NgxEditorModule,
    NgxMatSelectSearchModule,
    ColorPickerModule
  ],
  declarations: [
    ArticleComponent,
    ArticleCategoryComponent,
    ArticleEditComponent,
    ArticleCategoryEditDialogComponent],
  entryComponents:[ArticleCategoryEditDialogComponent]
})
export class ArticleModule { }
