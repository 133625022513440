import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatDialog, MatPaginatorIntl } from '@angular/material';
import { Story } from './story';
import { StoryService } from './story.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DeleteDialogComponent } from '../global/delete-dialog/delete-dialog.component';
import { SortDialogComponent } from '../global/sort-dialog/sort-dialog.component';

@Component({
  selector: 'app-story',
  templateUrl: './story.component.html',
  styleUrls: ['./story.component.css']
})
export class StoryComponent implements OnInit {

  tabIndex: number
  keyword: string
  status: string
  storiesDataSource = new MatTableDataSource<Story>();
  displayedColumns = ['name', 'action']
  totalCount: number
  size: number = 10;
  page: number = 0;

  @ViewChild('paginator') paginator: MatPaginator

  constructor(private storySrv: StoryService, private router: Router,
    private toastrSrv: ToastrService, private dialog: MatDialog,
    private matPaginatorIntl: MatPaginatorIntl) { }

  ngOnInit() {
    this.matPaginatorIntl.itemsPerPageLabel = '每頁筆數：';
    this.matPaginatorIntl.nextPageLabel = '下一頁';
    this.matPaginatorIntl.previousPageLabel = '上一頁';
    this.matPaginatorIntl.firstPageLabel = '第一頁'
    this.matPaginatorIntl.lastPageLabel = '最後一頁'
    this.tabIndex = 0
    this.refresh()
  }

  refresh() {
    this.status = this.tabIndex == 0 ? "ACTIVE" : "INACTIVE"
    if (this.keyword) {
      this.storySrv.search(this.status, this.keyword, this.page, this.size).subscribe(rep => this.setData(rep))
    } else {
      this.storySrv.getStoriesByStatus(this.status, this.page, this.size).subscribe(rep => this.setData(rep))
    }
  }

  setData(rep) {
    this.storiesDataSource.data = rep.body['content'];
    this.totalCount = rep.body['totalElements']
  }

  newStory() {
    this.router.navigate(['/dashboard', 'story', 'null'])
  }

  delete(story: Story) {
    let temp = []
    temp.push(story.id)
    const dialogRef = this.dialog.open(DeleteDialogComponent)
    dialogRef.afterClosed().subscribe(rep => {
      if (rep == "Yes") {
        this.storySrv.delete(temp).subscribe(rep => {
          if (rep.status == 200) {
            this.toastrSrv.success('刪除 ' + story.title['zh_TW'] + ' 成功', "success")
            this.afterDelete()
          }
        })
      }
    })
  }

  afterDelete() {
    if ((this.totalCount % 10) === 1 && this.totalCount != 1) {
      this.page -= 1
      this.paginator.previousPage()
    } else {
      this.refresh()
    }
  }

  changeTab() {
    this.keyword = ""
    this.page = 0
    this.size = 10
    this.refresh()
  }

  search() {
    this.paginator.firstPage();
    this.refresh();
  }

  clear() {
    this.paginator.firstPage();
    this.keyword = ""
    this.refresh()
  }

  pageChanged(event) {
    this.page = event.pageIndex
    this.size = event.pageSize
    this.refresh()
  }

  changeSort() {
    this.dialog.open(SortDialogComponent, { data: "story" })
  }


}
